import {
  Action,
  DatabaseAction,
  ExpandedAction,
} from "../business-logic/ActionBehavior/Action";
import { HttpRequestMethod, send } from "./utils/send";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { SimpleSelectOption } from "../components/simple-select/simpleSelectOptions";

export const ASTERISK_KEYBOARD_SHORTCUT_INFO = "*";

// TODO: cut out options that have already been taken
export const ALPHABET = "abcdefghijklmnopqrstuvwxyz";
export type AlphaChar =
  | "a"
  | "b"
  | "c"
  | "d"
  | "e"
  | "f"
  | "g"
  | "h"
  | "i"
  | "j"
  | "k"
  | "l"
  | "m"
  | "n"
  | "o"
  | "p"
  | "q"
  | "r"
  | "s"
  | "t"
  | "u"
  | "v"
  | "w"
  | "x"
  | "y"
  | "z";

type KeyboardShortcutResult = {
  action: Action | "" | "TAG PREVIOUS EVENT";
  // TODO: I wish we were storing this as string[] in the database.
  tags: SimpleSelectOption[];
};

export type KeyboardShortcutItem = {
  shortcut: AlphaChar | "";
} & KeyboardShortcutResult;

export type KeyboardShortcutsSchema = KeyboardShortcutItem[];

// WARNING: YouTube shortcuts can not be implemented here, as it would cause shortcuts that trigger multiple events.
export const defaultKeyboardShortcuts: KeyboardShortcutsSchema = [
  { shortcut: "t", action: DatabaseAction.tackled, tags: [] },
  { shortcut: "r", action: DatabaseAction.ruck, tags: [] },
  { shortcut: "f", action: ExpandedAction.tackledAndRuck, tags: [] },
  { shortcut: "d", action: DatabaseAction.phase, tags: [] },
  { shortcut: "e", action: DatabaseAction.offload, tags: [] },
  { shortcut: "a", action: DatabaseAction.openPlayKick, tags: [] },
  { shortcut: "v", action: DatabaseAction.receiveKick, tags: [] },
  { shortcut: "c", action: DatabaseAction.recoverKick, tags: [] },
  { shortcut: "g", action: DatabaseAction.turnover, tags: [] },
  { shortcut: "q", action: ExpandedAction.homeTeamPenalty, tags: [] },
  { shortcut: "w", action: ExpandedAction.awayTeamPenalty, tags: [] },
  { shortcut: "z", action: ExpandedAction.homeTeamKnockOn, tags: [] },
  { shortcut: "x", action: ExpandedAction.awayTeamKnockOn, tags: [] },
  { shortcut: "s", action: DatabaseAction.forwardPass, tags: [] },
];

const sendPutKeyboardShortcuts = (
  keyboardShortcuts: KeyboardShortcutsSchema
): Promise<boolean> =>
  send<boolean>(
    "/keyboard-shortcuts",
    HttpRequestMethod.PUT,
    keyboardShortcuts.map((keyboardShortcut) => {
      return {
        ...keyboardShortcut,
        // @ts-ignore: Our typing is messed up for us to need this.
        action: Number(keyboardShortcut.action),
      };
    })
  );

const sendGetKeyboardShortcuts = (): Promise<KeyboardShortcutsSchema> =>
  send<KeyboardShortcutsSchema | null>(
    "/keyboard-shortcuts",
    HttpRequestMethod.GET
  ).then((result) => {
    if (result !== null) {
      return result;
    }

    // If there are no keyboard shortcuts, create the default ones. Do not wait for the response.
    sendPutKeyboardShortcuts(defaultKeyboardShortcuts);
    return defaultKeyboardShortcuts;
  });

export const useKeyboardShortcuts = (): KeyboardShortcutItem[] | undefined => {
  return useQuery(["keyboardShortcuts"], sendGetKeyboardShortcuts).data;
};

export const useGetActionKeyboardShortcut = () => {
  const keyboardShortcuts = useKeyboardShortcuts();
  return (action: Action): string | undefined => {
    const keyboardShortcutItems = Object.entries(
      keyboardShortcuts ?? []
    ).filter(([, value]) => value.action === action);

    if (keyboardShortcutItems.length === 0) {
      return undefined;
    }

    return keyboardShortcutItems.length === 1 &&
      keyboardShortcutItems[0][1].tags.length === 0
      ? keyboardShortcutItems[0][1].shortcut
      : ASTERISK_KEYBOARD_SHORTCUT_INFO;
  };
};

export const useUpdateKeyboardShortcuts = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (keyboardShortcuts: KeyboardShortcutsSchema) =>
      sendPutKeyboardShortcuts(keyboardShortcuts),
    onMutate: async (keyboardShortcuts) => {
      queryClient
        .cancelQueries(["keyboardShortcuts"])
        .then(() =>
          queryClient.setQueryData(["keyboardShortcuts"], keyboardShortcuts)
        );
    },
  });
};
