import { SingleActionConfig } from "./SingleActionConfig";
import { Action, DatabaseAction, ExpandedAction } from "../Action";
import { ActionConfig } from "./ActionConfig";
import { Match } from "../../../requests/matches";
import { DetailedEvent } from "../../../requests/matchEvents";

export class KickoffActionConfig extends SingleActionConfig {
  isValid(
    previousEvents: DetailedEvent[],
    pseudoEventActions: Set<ExpandedAction>,
    actionConfigs: Record<Action, ActionConfig>
  ): boolean {
    return (
      previousEvents.length === 0 ||
      super.isValid(previousEvents, pseudoEventActions, actionConfigs)
    );
  }

  protected getIsPossessionSwitch = (
    previousEvents: DetailedEvent[],
    possessionChangePseudoEvent: boolean,
    match: Match
  ): boolean => {
    const mostRecentNonFlagEvent =
      this.getMostRecentNonFlagEvent(previousEvents);
    if (mostRecentNonFlagEvent === undefined) {
      return possessionChangePseudoEvent;
    }

    if (
      [DatabaseAction.conversionKickMiss, DatabaseAction.goalKickMade].includes(
        mostRecentNonFlagEvent.action_type_id
      )
    ) {
      // In 15s, the scoring team retains possession, so we must change the possession for the kickoff.
      return match.match_type_id === 1 /* 15s */;
    } else {
      return super.getIsPossessionSwitch(
        previousEvents,
        possessionChangePseudoEvent,
        match
      );
    }
  };
}
