import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

type Props = {
  title?: string;
  message: string;
  isOpen: boolean;
  onCloseDialog: () => void;
  onConfirmDialog: () => void;
  closeText?: string;
  confirmText?: string;
};

export const AlertDialog = ({
  title,
  message,
  isOpen,
  onCloseDialog,
  onConfirmDialog,
  closeText,
  confirmText,
}: Props) => (
  <>
    <Dialog open={isOpen} onClose={onCloseDialog} sx={{ m: 0 }}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent sx={{ mt: 2, mb: 0.5, mx: 2, p: 0 }}>
        <DialogContentText sx={{ textAlign: "center" }}>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{ mt: 0.5, mb: 2, mx: 2, p: 0, justifyContent: "center" }}
      >
        <Button onClick={onCloseDialog} variant="outlined">
          {closeText || "Cancel"}
        </Button>
        <Button
          onClick={() => {
            onConfirmDialog();
            onCloseDialog();
          }}
          variant="outlined"
          color="error"
        >
          {confirmText || "Delete"}
        </Button>
      </DialogActions>
    </Dialog>
  </>
);
