import assert from "assert";
import { ActionConfig } from "../business-logic/ActionBehavior/ActionConfig/ActionConfig";
import { SingleActionConfig } from "../business-logic/ActionBehavior/ActionConfig/SingleActionConfig";
import { PossessionChangeActionConfig } from "../business-logic/ActionBehavior/ActionConfig/NonDatabaseActionActionConfig/PossessionChangeActionConfig";
import { LineoutActionConfig } from "../business-logic/ActionBehavior/ActionConfig/LineoutActionConfig";
import { DynamicPossessionActionConfig } from "../business-logic/ActionBehavior/ActionConfig/DynamicPossessionActionConfig";
import { MultiActionConfig } from "../business-logic/ActionBehavior/ActionConfig/MultiActionConfig";
import {
  Action,
  DatabaseAction,
  ExpandedAction,
} from "../business-logic/ActionBehavior/Action";
import { OverrideActionConfig } from "../business-logic/ActionBehavior/ActionConfig/NonDatabaseActionActionConfig/OverrideActionConfig";
import { KickoffActionConfig } from "../business-logic/ActionBehavior/ActionConfig/KickoffActionConfig";
import { AlwaysValidActionConfig } from "../business-logic/ActionBehavior/ActionConfig/AlwaysValidActionConfig";
import { TurnoverActionConfig } from "../business-logic/ActionBehavior/ActionConfig/TurnoverActionConfig";

const freeKickActions = [
  DatabaseAction.lineout,
  DatabaseAction.kickRestart,
  DatabaseAction.scrum,
  DatabaseAction.tapRestart,
  DatabaseAction.endOfHalf,
];
const penaltyActions = [
  ...freeKickActions,
  DatabaseAction.penaltyGoalKick,
  DatabaseAction.try,
];
const openKickActions = [
  DatabaseAction.ballGrounded,
  DatabaseAction.chargedDownKick,
  DatabaseAction.intoTouch,
  DatabaseAction.knockOn,
  DatabaseAction.receiveKick,
  DatabaseAction.recoverKick,
  DatabaseAction.mark,
];
const openPlayActions = [
  DatabaseAction.dropGoalKick,
  DatabaseAction.forwardPass,
  DatabaseAction.heldUpTry,
  DatabaseAction.intoTouch,
  DatabaseAction.knockOn,
  DatabaseAction.maulStart,
  DatabaseAction.offload,
  DatabaseAction.openPlayKick,
  DatabaseAction.tackled,
  DatabaseAction.turnover,
  DatabaseAction.try,
  DatabaseAction.ballGrounded,
];
const restartActions = [DatabaseAction.knockOn, DatabaseAction.openPlayKick];
const pausePieceActions = [
  DatabaseAction.endOfHalf,
  DatabaseAction.forwardPass,
  DatabaseAction.freeKickWon,
  DatabaseAction.knockOn,
  DatabaseAction.maulStart,
  DatabaseAction.openPlayKick,
  DatabaseAction.phase,
  DatabaseAction.turnover,
];

export enum PossessionChange {
  currentEvent,
  nextEvent,
}

type ActionMapping = {
  previous: DatabaseAction[];
  next: DatabaseAction[];
  requiresPitchClick: boolean;
  possessionChange?: PossessionChange;
};

// TODO: action mapping for offload to not require location only if the previous action is a tackle
// TODO: ball grounded extra complexity with possession changing
// TODO: we could be smarter about when kickoff50_22_GL are acceptable for possession changing
// TODO: improve charged down kicks logic
const databaseActionsMap: ActionMapping[] = [
  {
    previous: [DatabaseAction.heldUpTry],
    next: [DatabaseAction.kickoff, DatabaseAction.endOfHalf],
    requiresPitchClick: true,
    possessionChange: PossessionChange.nextEvent,
  },
  {
    previous: [DatabaseAction.conversionKickMiss, DatabaseAction.goalKickMade],
    next: [DatabaseAction.kickoff, DatabaseAction.endOfHalf],
    requiresPitchClick: false,
    possessionChange: PossessionChange.nextEvent,
  },
  {
    previous: [DatabaseAction.conversionGoalKick],
    next: [DatabaseAction.goalKickMade, DatabaseAction.conversionKickMiss],
    requiresPitchClick: true,
  },
  {
    previous: [DatabaseAction.phase],
    next: openPlayActions,
    requiresPitchClick: false,
  },
  {
    previous: [DatabaseAction.offload],
    next: openPlayActions,
    requiresPitchClick: true,
  },
  {
    previous: [DatabaseAction.ballGrounded],
    next: [
      DatabaseAction.kickoff,
      DatabaseAction.scrum,
      DatabaseAction.endOfHalf,
    ],
    requiresPitchClick: true,
  },
  {
    previous: [DatabaseAction.chargedDownKick],
    next: [
      DatabaseAction.intoTouch,
      DatabaseAction.knockOn,
      DatabaseAction.receiveKick,
      DatabaseAction.recoverKick,
    ],
    requiresPitchClick: false,
    possessionChange: PossessionChange.currentEvent,
  },
  {
    previous: [DatabaseAction.freeKickWon, DatabaseAction.mark],
    next: freeKickActions,
    requiresPitchClick: true,
  },
  {
    previous: [DatabaseAction.penaltyWon],
    next: penaltyActions,
    requiresPitchClick: true,
  },
  {
    previous: [DatabaseAction.dropGoalKick, DatabaseAction.penaltyGoalKick],
    next: [...openKickActions, DatabaseAction.goalKickMade],
    requiresPitchClick: true,
  },
  {
    previous: [DatabaseAction.kickRestart, DatabaseAction.openPlayKick],
    next: openKickActions,
    requiresPitchClick: true,
  },
  {
    previous: [DatabaseAction.scrum],
    next: [...pausePieceActions, DatabaseAction.scrumReset],
    requiresPitchClick: true,
  },
  {
    previous: [DatabaseAction.ruck],
    next: pausePieceActions,
    requiresPitchClick: false,
  },
  {
    previous: [DatabaseAction.scrumReset],
    next: [DatabaseAction.scrum, DatabaseAction.endOfHalf],
    requiresPitchClick: false,
  },
  {
    previous: [DatabaseAction.maulStart],
    next: [
      DatabaseAction.intoTouch,
      DatabaseAction.knockOn,
      DatabaseAction.maulEnd,
      DatabaseAction.turnover,
      DatabaseAction.try,
    ],
    requiresPitchClick: true,
  },
  {
    previous: [DatabaseAction.tackled],
    next: [
      DatabaseAction.forwardPass,
      DatabaseAction.intoTouch,
      DatabaseAction.knockOn,
      DatabaseAction.offload,
      DatabaseAction.ruck,
      DatabaseAction.try,
      DatabaseAction.turnover,
    ],
    requiresPitchClick: true,
  },
  {
    previous: [DatabaseAction.maulEnd],
    next: [
      ...restartActions,
      DatabaseAction.phase,
      DatabaseAction.ruck,
      DatabaseAction.tackled,
      DatabaseAction.try,
      DatabaseAction.turnover,
    ],
    requiresPitchClick: true,
  },
  {
    previous: [DatabaseAction.tapRestart],
    next: [...openPlayActions, ...restartActions],
    requiresPitchClick: false,
  },
  {
    previous: [DatabaseAction.receiveKick],
    next: [
      DatabaseAction.intoTouch,
      DatabaseAction.knockOn,
      DatabaseAction.mark,
      DatabaseAction.offload,
      DatabaseAction.openPlayKick,
      DatabaseAction.phase,
      DatabaseAction.tackled,
      DatabaseAction.turnover,
      DatabaseAction.ballGrounded,
    ],
    requiresPitchClick: true,
    possessionChange: PossessionChange.currentEvent,
  },
  {
    previous: [DatabaseAction.recoverKick],
    next: openPlayActions,
    requiresPitchClick: true,
  },
  {
    previous: [DatabaseAction.forwardPass, DatabaseAction.knockOn],
    next: [
      DatabaseAction.endOfHalf,
      DatabaseAction.intoTouch,
      DatabaseAction.scrum,
      DatabaseAction.turnover,
    ],
    requiresPitchClick: true,
    possessionChange: PossessionChange.nextEvent,
  },
  {
    previous: [DatabaseAction.try],
    next: [DatabaseAction.conversionGoalKick],
    requiresPitchClick: true,
  },
  {
    previous: [DatabaseAction.endOfHalf],
    next: [DatabaseAction.kickoff],
    requiresPitchClick: false,
  },
  {
    previous: [DatabaseAction.intoTouch],
    next: [
      DatabaseAction.freeKickWon,
      DatabaseAction.kickoff,
      DatabaseAction.lineout,
      DatabaseAction.scrum,
      DatabaseAction.endOfHalf,
    ],
    requiresPitchClick: true,
  },
];

const DATABASE_ACTION_CONFIGS: Record<DatabaseAction, SingleActionConfig> =
  //  first loop through each `actionMapping` object
  databaseActionsMap.reduce((accumulator, actionMapping) => {
    // remove `previous` property from object
    const { previous, ...actionMappingValue } = actionMapping;

    // penalty action is always possible
    actionMappingValue.next = [
      ...actionMappingValue.next,
      DatabaseAction.penaltyWon,
    ];

    // then loop through each previous action in each `actionMapping` object
    const newMappings = previous.reduce((_accumulator, action) => {
      return {
        ..._accumulator,
        [action]: new SingleActionConfig(
          action,
          actionMappingValue.next,
          actionMappingValue.requiresPitchClick,
          actionMappingValue.possessionChange
        ),
      };
    }, {} as Record<DatabaseAction, SingleActionConfig>);

    const duplicateKeys = Object.keys(newMappings).filter((key) =>
      Object.keys(accumulator).includes(key)
    );
    assert(
      duplicateKeys.length === 0,
      `Duplicate actionMapping keys in previousValues: ${duplicateKeys
        .sort()
        .map((key) => DatabaseAction[key as keyof typeof DatabaseAction])}`
    );

    return { ...accumulator, ...newMappings };
  }, {} as Record<DatabaseAction, SingleActionConfig>);

DATABASE_ACTION_CONFIGS[DatabaseAction.turnover] = new TurnoverActionConfig(
  DatabaseAction.turnover,
  [
    ...openPlayActions,
    ...pausePieceActions,
    DatabaseAction.kickoff,
    DatabaseAction.lineout,
    DatabaseAction.scrum,
  ]
);

DATABASE_ACTION_CONFIGS[DatabaseAction.lineout] = new LineoutActionConfig(
  DatabaseAction.lineout,
  pausePieceActions,
  true
);

DATABASE_ACTION_CONFIGS[DatabaseAction.kickoff] = new KickoffActionConfig(
  DatabaseAction.kickoff,
  [...openKickActions, DatabaseAction.freeKickWon],
  true
);

// The value for nextActions does not matter, as we should ignore flag actions in isValid() to go to the previous event.
DATABASE_ACTION_CONFIGS[DatabaseAction.flag] = new AlwaysValidActionConfig(
  DatabaseAction.flag,
  [],
  true
);

export const EXPANDED_ACTION_CONFIGS: Record<ExpandedAction, ActionConfig> = {
  [ExpandedAction.homeTeamPenalty]: new DynamicPossessionActionConfig(
    ExpandedAction.homeTeamPenalty,
    DATABASE_ACTION_CONFIGS[DatabaseAction.penaltyWon],
    "PK Won",
    true
  ),
  [ExpandedAction.awayTeamPenalty]: new DynamicPossessionActionConfig(
    ExpandedAction.awayTeamPenalty,
    DATABASE_ACTION_CONFIGS[DatabaseAction.penaltyWon],
    "PK Won",
    false
  ),
  [ExpandedAction.homeTeamFreeKick]: new DynamicPossessionActionConfig(
    ExpandedAction.homeTeamFreeKick,
    DATABASE_ACTION_CONFIGS[DatabaseAction.freeKickWon],
    "FK Won",
    true
  ),
  [ExpandedAction.awayTeamFreeKick]: new DynamicPossessionActionConfig(
    ExpandedAction.awayTeamFreeKick,
    DATABASE_ACTION_CONFIGS[DatabaseAction.freeKickWon],
    "FK Won",
    false
  ),

  [ExpandedAction.homeTeamKnockOn]: new DynamicPossessionActionConfig(
    ExpandedAction.homeTeamKnockOn,
    DATABASE_ACTION_CONFIGS[DatabaseAction.knockOn],
    "Knock On",
    true
  ),
  [ExpandedAction.awayTeamKnockOn]: new DynamicPossessionActionConfig(
    ExpandedAction.awayTeamKnockOn,
    DATABASE_ACTION_CONFIGS[DatabaseAction.knockOn],
    "Knock On",
    false
  ),

  [ExpandedAction.tackledAndRuck]: new MultiActionConfig(
    ExpandedAction.tackledAndRuck,
    [
      DATABASE_ACTION_CONFIGS[DatabaseAction.tackled],
      DATABASE_ACTION_CONFIGS[DatabaseAction.ruck],
    ]
  ),
  [ExpandedAction.possessionChange]: new PossessionChangeActionConfig(),
  [ExpandedAction.override]: new OverrideActionConfig(),
};

export const ACTION_CONFIGS: Record<Action, ActionConfig> = {
  ...DATABASE_ACTION_CONFIGS,
  ...EXPANDED_ACTION_CONFIGS,
};

const potentialStateActions = Object.values(DATABASE_ACTION_CONFIGS).reduce(
  (accumulator, actionMappingValue) => {
    actionMappingValue.nextActions?.forEach((action) =>
      action in DatabaseAction
        ? accumulator.add(action as DatabaseAction)
        : null
    );
    return accumulator;
  },
  new Set<DatabaseAction>()
);
const missingKeys = Array.from(potentialStateActions).filter(
  (id) => !Object.keys(DATABASE_ACTION_CONFIGS).includes(String(id))
);

assert(
  !missingKeys.length,
  `Missing previous key for values in next: ${missingKeys
    .sort()
    .map((key) => DatabaseAction[key])}`
);
