import { StatItem } from "./StatItem";
import { Data } from "plotly.js-basic-dist";
import Plotly from "plotly.js-basic-dist";
import createPlotlyComponent from "react-plotly.js/factory";
import { Team } from "../../requests/teams";

type Props = {
  stats: StatItem[];
  homeTeam: Team;
  awayTeam: Team;
};

export const PlotlyBarCharts = ({ stats, homeTeam, awayTeam }: Props) => {
  const homeValues: number[] = [];
  const awayValues: number[] = [];
  const homeValuesNormalized: number[] = [];
  const awayValuesNormalized: number[] = [];
  const metricNames: string[] = [];

  const Plot = createPlotlyComponent(Plotly);

  // Iterate over each stat to collect all values and their corresponding metrics
  stats.forEach((stat: StatItem) => {
    let homeValue = parseFloat(stat.home) || 0;
    let awayValue = parseFloat(stat.away) || 0;
    let total = homeValue + awayValue;
    homeValues.push(homeValue);
    awayValues.push(awayValue);
    homeValuesNormalized.push((homeValue / total) * 100);
    awayValuesNormalized.push((awayValue / total) * 100);
    metricNames.push(stat.metric);
  });

  const homeBar: Data = {
    name: homeTeam.name,
    x: homeValuesNormalized,
    y: metricNames,
    text: homeValues.map((value) => value.toString()),
    textposition: "inside",
    insidetextanchor: "start",
    type: "bar",
    marker: {
      color: homeTeam.color,
      line: { color: "#000000", width: 2 },
    },
    orientation: "h",
    width: 0.5,
  };

  const awayBar: Data = {
    name: awayTeam.name,
    x: awayValuesNormalized,
    y: metricNames,
    text: awayValues.map((value) => value.toString()),
    type: "bar",
    marker: {
      color: awayTeam.color,
      line: { color: "#000000", width: 2 },
    },
    orientation: "h",
    width: 0.5,
  };

  type Annotation = {
    x: number;
    y: number;
    text: string;
    showarrow: boolean;
    font: {
      size: number;
      weight: string;
    };
  };
  const annotations: Annotation[] = [];
  for (let i = 0; i < metricNames.length; i++) {
    annotations.push({
      x: 50,
      y: i - 0.45,
      text: metricNames[i],
      showarrow: false,
      font: {
        size: 15,
        weight: "bold",
      },
    });
  }

  const chartData = [homeBar, awayBar];

  return (
    <Plot
      data={chartData}
      layout={{
        barmode: "relative",
        title: "none",
        xaxis: { showgrid: false, visible: false },
        yaxis: { showgrid: false, visible: false, autorange: "reversed" }, //position: .5, ticklabelposition: "outside right"},
        margin: { t: 0, b: 0, l: 5, r: 5 },
        legend: { orientation: "h", x: 0.5, y: 0, xanchor: "center" },
        autosize: true,
        width: 600,
        height: 400,
        paper_bgcolor: "#ffffff",
        plot_bgcolor: "#ffffff",
        hovermode: false,
        annotations: annotations,
      }}
      config={{ displayModeBar: false }}
    />
  );
};
