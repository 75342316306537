import { ActionConfig } from "./ActionConfig";
import { Action, DatabaseAction, ExpandedAction } from "../Action";
import { Dispatch, SetStateAction } from "react";
import pitch from "../../../components/rugbyPitch";
import {
  ACTION_CONFIGS,
  PossessionChange,
} from "../../../utils/actionMappings";
import assert from "assert";

import {
  DetailedEvent,
  PostMatchEventResponse,
} from "../../../requests/matchEvents";
import { Match } from "../../../requests/matches";

export class SingleActionConfig extends ActionConfig {
  readonly databaseAction?: DatabaseAction;

  constructor(
    action: Action,
    nextActions: DatabaseAction[],
    requiresPitchClick:
      | boolean
      | ((previousEvents: DetailedEvent[]) => boolean),
    possessionChange?: PossessionChange,
    databaseAction?: DatabaseAction
  ) {
    // if type is boolean, turn to a function
    const getRequiresPitchClick =
      typeof requiresPitchClick === "boolean"
        ? () => requiresPitchClick
        : requiresPitchClick;
    super(action, nextActions, getRequiresPitchClick, possessionChange);

    if (databaseAction) {
      this.databaseAction = databaseAction;
    } else if (action in DatabaseAction) {
      this.databaseAction = action as DatabaseAction;
      // If the action is not in DatabaseAction, doAction() and isValid() must be overwritten by the subclass.
    } else {
      this.databaseAction = undefined;
    }
  }

  getClickPhrase = (actionTypes: Record<Action, string>) =>
    actionTypes[this.action];

  doAction = async (
    previousEvents: DetailedEvent[],
    createEvent: (
      action: DatabaseAction,
      possessionTeamId: number,
      locX: number,
      locY: number
    ) => Promise<PostMatchEventResponse>,
    pseudoEventActions: Set<ExpandedAction>,
    setPseudoEventActions: Dispatch<SetStateAction<Set<ExpandedAction>>>,
    match: Match,
    locX?: number,
    locY?: number
  ): Promise<void> => {
    // If the action is not in DatabaseAction, doAction() and isValid() must be overwritten by the subclass.
    assert(this.databaseAction);

    if (locX && locY) {
      locX = Math.min(locX, pitch.xMax);
      locX = Math.max(locX, pitch.xMin);
      locY = Math.min(locY, pitch.yMax);
      locY = Math.max(locY, pitch.yMin);
    }

    const mostRecentEvent = previousEvents[0];
    locX = locX ?? mostRecentEvent?.loc_x ?? 0;
    locY = locY ?? mostRecentEvent?.loc_y ?? 0;

    const isHomeTeamPossession = this.getIsHomeTeamPossession(
      previousEvents,
      match,
      pseudoEventActions.has(ExpandedAction.possessionChange)
    );
    const possessionTeamId = (
      isHomeTeamPossession ? match.home_team_id : match.away_team_id
    ) as number;

    // We must create the event before setting the pseudoEventActions, because the pseudoEventActions determine event properties like possession.
    await createEvent(this.databaseAction, possessionTeamId, locX, locY);
    setPseudoEventActions(new Set());
  };

  getIsHomeTeamPossession = (
    previousEvents: DetailedEvent[],
    match: Match,
    isPossessionChangePseudoEventAction: boolean
  ): boolean =>
    this.getIsPossessionSwitch(
      previousEvents,
      isPossessionChangePseudoEventAction,
      match
    ) !==
    (this.getMostRecentNonFlagEvent(previousEvents)?.begin_team_id ===
      match.home_team_id);

  isValid(
    previousEvents: DetailedEvent[],
    pseudoEventActions: Set<ExpandedAction>,
    actionConfigs: Record<Action, ActionConfig>
  ): boolean {
    return (
      pseudoEventActions.has(ExpandedAction.override) ||
      this.getIsValidByEvents(previousEvents, actionConfigs)
    );
  }

  protected getNonFlagEvents = (
    previousEvents: DetailedEvent[]
  ): DetailedEvent[] =>
    previousEvents.filter(
      (event) => event.action_type_id !== DatabaseAction.flag
    );

  protected getMostRecentNonFlagEvent = (
    previousEvents: DetailedEvent[]
  ): DetailedEvent | undefined =>
    this.getNonFlagEvents(previousEvents)[0] ?? undefined;

  protected getIsValidByEvents = (
    previousEvents: DetailedEvent[],
    actionConfigs: Record<Action, ActionConfig>
  ): boolean => {
    const mostRecentNonFlagEvent =
      this.getMostRecentNonFlagEvent(previousEvents);
    return mostRecentNonFlagEvent
      ? actionConfigs[
          mostRecentNonFlagEvent.action_type_id
        ]?.nextActions?.includes(this.databaseAction as DatabaseAction) ?? false
      : false;
  };

  protected getIsPossessionSwitch(
    previousEvents: DetailedEvent[],
    possessionChangePseudoEvent: boolean,
    _match: Match
  ): boolean {
    const mostRecentNonFlagEvent =
      this.getMostRecentNonFlagEvent(previousEvents);
    if (mostRecentNonFlagEvent === undefined) {
      return possessionChangePseudoEvent;
    }

    return (
      possessionChangePseudoEvent ||
      ACTION_CONFIGS[this.action].possessionChange ===
        PossessionChange.currentEvent ||
      ACTION_CONFIGS[mostRecentNonFlagEvent.action_type_id]
        ?.possessionChange === PossessionChange.nextEvent
    );
  }
}
