import { GridValidRowModel } from "@mui/x-data-grid/models/gridRows";
import { ImmutableGridCol } from "./ImmutableGridCol";
import { ColorButton } from "../../ColorButton";
import * as React from "react";
import { ReactElement } from "react";
import { GridColType } from "@mui/x-data-grid";
import { GridRowParams } from "@mui/x-data-grid/models/params/gridRowParams";
import { GridActionsCellItemProps } from "@mui/x-data-grid/components/cell/GridActionsCellItem";

export class ButtonGridCol<
  Item extends GridValidRowModel
> extends ImmutableGridCol<Item> {
  type: GridColType = "actions";
  getActions: (
    params: GridRowParams
  ) => ReactElement<GridActionsCellItemProps>[];

  constructor(
    field: Extract<keyof Item, string>,
    text: string,
    width: number,
    onClick: (params: GridRowParams) => void,
    color: string,
    disabled: (params: GridRowParams) => boolean = () => false
  ) {
    super(field, "", width);
    this.getActions = (params) => [
      <ColorButton
        onClick={() => onClick(params)}
        color={color}
        variant="contained"
        disabled={disabled(params)}
      >
        {text}
      </ColorButton>,
    ];
  }
}
