import { HttpRequestMethod, send } from "./utils/send";

export const sendPutComment = async (
  matchId: number,
  matchEventId: number,
  comment: string
): Promise<boolean> =>
  send<boolean>(
    `/matches/${matchId}/events/${matchEventId}/comments`,
    HttpRequestMethod.PUT,
    { comment }
  );
