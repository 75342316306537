import assert from "assert";
import {
  Autocomplete,
  AutocompleteChangeReason,
  createFilterOptions,
  FilterOptionsState,
  TextField,
} from "@mui/material";
import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import { transformTag } from "../utils/transformTag";
import { LockableIconButton } from "./LockableIconButton";
import { TagChip } from "./TagChip";
import { DetailedEvent } from "../requests/matchEvents";
import {
  useAddTagOptionToState,
  useAddTagsSync,
  useDeleteTagSync,
  useTags,
} from "../requests/tags";

const MAX_VISIBLE_TAG_LENGTH = 12;

type TagsInputProps = {
  event: DetailedEvent;
  // On click, pause the video to prevent the video from playing while editing tags.
  pauseVideo: () => void;
  disabled?: boolean;
};

export const TagsInput = ({
  event,
  pauseVideo,
  disabled = false,
}: TagsInputProps) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const [focusedTag, setFocusedTag] = useState<string | null>(null);

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (
        focusedTag &&
        ref.current &&
        !ref.current.contains(e.target as Node)
      ) {
        setFocusedTag(null);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [focusedTag]);

  const tagOptions = useTags().map((tag) => tag.name);
  const addTagToState = useAddTagOptionToState();

  const addEventTag = useAddTagsSync();
  const deleteTag = useDeleteTagSync();

  const filter = createFilterOptions<string>();
  const filterOptions = (
    options: string[],
    state: FilterOptionsState<string>
  ) => {
    state = { ...state, inputValue: transformTag(state.inputValue) };
    const filtered = filter(options, state);
    const { inputValue } = state;
    const inputMatchesOption = options.some((option) => inputValue === option);
    if (inputValue && !inputMatchesOption) {
      filtered.push(inputValue);
    }
    return filtered;
  };

  if (!tagOptions) {
    return <></>;
  }

  const onChange = (
    _: SyntheticEvent,
    tags: readonly string[] | string | null,
    reason: AutocompleteChangeReason,
    event: DetailedEvent
  ): void => {
    if (reason === "removeOption") {
      return;
    }
    assert(reason === "selectOption" || reason === "createOption");
    assert(tags && typeof tags !== "string" && tags.length);

    const newTag = transformTag(tags.slice(-1)[0]);
    // Prevent creation of an empty-string tag.
    if (newTag === "") {
      return;
    }

    addEventTag(event, [newTag]);
    addTagToState(newTag);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "0.5em",
        alignContent: "left",
        pointerEvents: "none",
      }}
    >
      <Autocomplete<string, boolean, boolean, boolean>
        // sx={{ pointerEvents: "none" }}
        openOnFocus={true}
        ListboxProps={{ style: { fontSize: ".75em" } }}
        size="small"
        onClick={() => {}}
        options={tagOptions}
        filterOptions={filterOptions}
        getOptionLabel={(option) => transformTag(option ?? "")}
        multiple
        value={event.tags ?? []}
        onChange={(_event, value, reason) =>
          onChange(_event, value, reason, event)
        }
        clearOnBlur
        blurOnSelect
        disableClearable
        filterSelectedOptions
        freeSolo
        includeInputInList
        renderTags={(tags, getTagProps) =>
          tags.map((tag: string, index: number) => {
            const { key, ...tagProps } = getTagProps({ index });
            return (
              <TagChip
                {...tagProps}
                value={tag}
                isSelected={focusedTag === tag}
                key={key}
                maxVisibleLength={MAX_VISIBLE_TAG_LENGTH}
                chipProps={{
                  onClick: (e) => {
                    setFocusedTag(tag);
                    pauseVideo();
                    e.stopPropagation();
                  },
                }}
                onDelete={() => deleteTag(event, tag)}
              />
            );
          })
        }
        renderInput={(params) => (
          <TextField
            ref={ref}
            {...params}
            id="tags-input"
            variant="standard"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <LockableIconButton
                    lockMessage="Cannot add tags while another event is selected."
                    isLocked={disabled}
                    onClick={pauseVideo}
                    sx={{
                      pointerEvents: "auto",
                      cursor: "context-menu",
                    }}
                  >
                    <LocalOfferOutlinedIcon />
                  </LockableIconButton>
                  {params.InputProps.startAdornment}
                </>
              ),

              disableUnderline: true,
              style: { fontSize: "1em", pointerEvents: "auto" },
              disabled: disabled,
            }}
            inputProps={{
              ...params.inputProps,
            }}
            sx={{
              // pointerEvents: "none", // Disable pointer events for the entire input field
              // ".MuiInputAdornment-root": {
              //   pointerEvents: "auto", // Re-enable pointer events for the adornment
              // },
              ".MuiBadge-root": {
                pointerEvents: "auto", // Re-enable pointer events for the adornment
                cursor: "pointer",
              },
              ".MuiChip-root": {
                pointerEvents: "auto", // Re-enable pointer events for the adornment
                cursor: "pointer",
              },
              // ".MuiInputBase-input": {
              //   pointerEvents: "none", // Ensure the input itself has no pointer events
              //   maxWidth: 0,
              // },
              // ".MuiInputBase-root": {
              //   pointerEvents: "none", // Ensure the input itself has no pointer events
              // },
            }}
          />
        )}
      />
    </div>
  );
};
