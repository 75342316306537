import { GridValidRowModel } from "@mui/x-data-grid/models/gridRows";
import { MutableGridCol } from "./MutableGridCol";
import { GridRenderCellParams } from "@mui/x-data-grid";
import * as React from "react";
import { ReactNode } from "react";
import { SimpleSelectOption } from "../../simple-select/simpleSelectOptions";
import { ChipSelect } from "../../simple-select/ChipSelect";
import { XXL_COLUMN } from "../../../utils/columnSizes";

export class MultiSelectGridCol<
  Item extends GridValidRowModel
> extends MutableGridCol<Item> {
  renderCell: (params: GridRenderCellParams<Item>) => ReactNode;
  renderEditCell: (params: GridRenderCellParams<Item>) => ReactNode;

  constructor(
    field: Extract<keyof Item, string>,
    headerName: string,
    options: SimpleSelectOption[]
  ) {
    super(field, headerName, XXL_COLUMN);

    type ChipSelectForGridColProps = {
      params: GridRenderCellParams;
      disabled: boolean;
    };
    const ChipSelectForGridCol = ({
      params,
      disabled,
    }: ChipSelectForGridColProps) => {
      return (
        <ChipSelect
          options={options}
          values={params.value}
          setValues={(newValues) => {
            params.api.setEditCellValue({
              id: params.id,
              field: field,
              value: newValues(params.value),
            });
          }}
          disabled={disabled}
          widthFactor={1.8}
        />
      );
    };

    this.renderCell = (params: GridRenderCellParams<Item>) => (
      // <ChipSelectForGridCol params={params} disabled={true} />
      <div>
        {params.value
          .map((_value: SimpleSelectOption) => _value.value)
          .join(", ")}
      </div>
    );
    this.renderEditCell = (params) => (
      <ChipSelectForGridCol params={params} disabled={false} />
    );
  }
}
