import assert from "assert";

const padToTwoDigits = (num: number) => {
  assert(num >= 0 && num < 100);
  return num < 10 ? "0" + num : num;
};

export const renderTime = (timeInMs: number) => {
  const timeInSeconds = Math.floor(timeInMs / 1000);
  return `${Math.floor(timeInSeconds / 60)}:${padToTwoDigits(
    timeInSeconds % 60
  )}`;
};
