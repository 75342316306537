import { IconButton, Popover, Stack, Typography } from "@mui/material";
import React, { Dispatch, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ColorPalette } from "../../utils/constants";

export const ShareUrlPopover = ({
  sharePopover,
  setSharePopover,
}: {
  sharePopover: HTMLButtonElement | null;
  setSharePopover: Dispatch<HTMLButtonElement | null>;
}) => {
  const [isUrlHighlighted, setIsUrlHighlighted] = useState(false);

  const onClickCopyUrl = () => {
    // noinspection JSIgnoredPromiseFromCall
    navigator.clipboard.writeText(window.location.toString());
    setIsUrlHighlighted(true);
    setTimeout(() => {
      setIsUrlHighlighted(false);
    }, 1000);
  };

  return (
    <Popover
      open={Boolean(sharePopover)}
      anchorEl={sharePopover}
      onClose={() => setSharePopover(null)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Stack sx={{ p: 2 }} alignItems="center" direction="row" gap={2}>
        <IconButton size="small" onClick={onClickCopyUrl}>
          <ContentCopyIcon />
        </IconButton>
        <Typography
          sx={
            isUrlHighlighted
              ? {
                  backgroundColor: ColorPalette.lightBlue,
                  transition: "background-color 1s ease",
                }
              : {
                  backgroundColor: "none",
                  transition: "background-color 1s ease",
                }
          }
        >
          {window.location.toString()}
        </Typography>
      </Stack>
    </Popover>
  );
};
