import * as React from "react";
import { LARGE_COLUMN, XXL_COLUMN } from "../utils/columnSizes";
import {
  useCreateInternalUser,
  useDeleteUser,
  User,
  UserPermission,
  useUpdateUser,
  useUsers,
} from "../requests/users";
import { CustomSpinner } from "../components/CustomSpinner";
import { isValidEmail } from "../utils/isValidEmail";
import { SelectGridCol } from "../components/CrudTable/columns/SelectGridCol";
import { MutableGridCol } from "../components/CrudTable/columns/MutableGridCol";
import { FullFeaturedCrudGrid } from "../components/CrudTable/FullFeaturedCrudGrid";
import { useMatches } from "../requests/matches";

export const Users = () => {
  const users = useUsers();
  const createUser = useCreateInternalUser().mutateAsync;
  const updateUser = useUpdateUser().mutate;
  const deleteUser = useDeleteUser().mutate;

  const matches = useMatches();

  const isValidUser = (user?: User) => {
    const isExistingUser = users?.find((u) => u.id === user?.id);
    if (!user?.name?.trim()?.length) {
      return "Name is required.";
    } else if (!user?.permission_level_id) {
      return "Permission is required.";
    } else if (!isValidEmail(user?.email)) {
      return "Email is invalid.";
    } else if (
      isExistingUser &&
      user?.email !== users?.find((u) => u.id === user?.id)?.email
    ) {
      return "Unable to change the email of an existing user.";
    }
    return true;
  };

  const columns = [
    new MutableGridCol("name", "Name", XXL_COLUMN),
    new SelectGridCol<User>(
      "permission_level_id",
      "Permission",
      LARGE_COLUMN,
      Object.keys(UserPermission)
        .filter((key) => isNaN(Number(key)))
        .map((permission) => ({
          value: UserPermission[permission as keyof typeof UserPermission], // numeric representation
          label: permission, // string representation
        })),
      (params) => UserPermission[params.value]
    ),
    new MutableGridCol("email", "Email", XXL_COLUMN),
  ];

  if (!users || !matches) {
    return <CustomSpinner />;
  }

  const getNewRow = (id: number): User => ({
    id,
    name: "",
    permission_level_id: UserPermission.read,
    email: "",
  });

  const getDeleteDialog = (user: User) => `Delete ${user?.name}?`;

  return (
    <FullFeaturedCrudGrid
      crudRequests={{
        isStoredAsBlob: false,
        useCollection: useUsers,
        postSingleton: (user: User) => createUser(user),
        putSingleton: (user: User) => updateUser(user),
        deleteSingleton: (id: number) => deleteUser(id),
      }}
      validators={{
        isValidCreateUpdate: isValidUser,
      }}
      columns={columns}
      getNewRow={getNewRow}
      getDeleteDialog={getDeleteDialog}
      itemName="user"
      lockKey="max_users"
      initialSortModel={[{ field: "name", sort: "asc" }]}
      fieldToFocus="name"
    />
  );
};
