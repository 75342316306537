import { QueryKey, useMutation, useQueryClient } from "@tanstack/react-query";
import { CrudType } from "./CrudType";

export const getUseUpdate =
  <T extends CrudType>(
    queryKey: QueryKey,
    sendPutItem: (item: T) => Promise<boolean>
  ) =>
  () => {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (item: T) => sendPutItem(item),
      onMutate: async (item) => {
        queryClient.cancelQueries([...queryKey, item.id]).then(() =>
          queryClient.setQueryData(queryKey, (prevItems: T[] = []) => {
            return prevItems.map((prevItem) =>
              prevItem.id === item.id ? item : prevItem
            );
          })
        );
      },
    });
  };
