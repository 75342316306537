import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React from "react";
import { useTeams } from "../../requests/teams";

import { Match } from "../../requests/matches";

type Props = {
  match: Match;
  isHomeTeamPossession: boolean;
  onClick: (isHomeTeamPossession: boolean) => void;
  disabled?: boolean;
};

export const Possession = ({
  match,
  isHomeTeamPossession,
  onClick,
  disabled = false,
}: Props) => {
  const teams = useTeams();
  return (
    <RadioGroup
      sx={{ display: "inline-block" }}
      value={isHomeTeamPossession}
      defaultValue={true}
      onChange={(e) => onClick(e.target.value === "true")}
    >
      <FormControlLabel
        control={<Radio />}
        label={teams?.find((team) => team.id === match.home_team_id)?.name}
        value={true}
        disabled={disabled}
      />
      <FormControlLabel
        control={<Radio />}
        label={teams?.find((team) => team.id === match.away_team_id)?.name}
        value={false}
        disabled={disabled}
      />
    </RadioGroup>
  );
};
