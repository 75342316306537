import { MutableGridCol } from "./MutableGridCol";
import { MuiColorInput } from "mui-color-input";
import * as React from "react";
import { ReactNode } from "react";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { GridValidRowModel } from "@mui/x-data-grid/models/gridRows";

export class ColorPickerGridCol<
  Item extends GridValidRowModel
> extends MutableGridCol<Item> {
  renderCell: (params: GridRenderCellParams<Item>) => ReactNode;
  renderEditCell: (params: GridRenderCellParams<Item>) => ReactNode;

  constructor(
    field: Extract<keyof Item, string>,
    headerName: string,
    width: number
  ) {
    super(field, headerName, width);
    this.renderCell = (params: GridRenderCellParams<Item>) => (
      <MuiColorInput
        value={params.value}
        isAlphaHidden
        format="hex"
        size="small"
        disabled
      />
    );
    this.renderEditCell = (params) => (
      <MuiColorInput
        value={params.value}
        onChange={(color) =>
          params.api.setEditCellValue({ ...params, value: color })
        }
        fallbackValue={params.value}
        isAlphaHidden
        format="hex"
        size="small"
      />
    );
  }
}
