// TODO: continue to clean this file up
import * as d3 from "d3";
import React, { useState } from "react";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { Data } from "plotly.js-basic-dist";
import {
  SimpleSelectOption,
  useActionOptions,
  useMatchOptions,
} from "../components/simple-select/simpleSelectOptions";
import { SingleSelect } from "../components/simple-select/SimpleSelect";
import { CSVLink } from "react-csv";
import { MetricEvent, useMatchMetricEvents } from "../requests/metricEvents";

import { CustomSpinner } from "../components/CustomSpinner";
import { ChipSelect } from "../components/simple-select/ChipSelect";
import { useAwayTeam, useHomeTeam } from "../requests/teams";
import { MatchScatterPlot } from "../components/Analytics/MatchScatterPlot";
import { ColorPalette } from "../utils/constants";
import { StatItem } from "../components/Analytics/StatItem";
import { PlotlyBarCharts } from "../components/Analytics/PlotlyBarCharts";
import { DatabaseAction } from "../business-logic/ActionBehavior/Action";

import { useActionTypes } from "../requests/actionTypes";

type Group = {
  group: string;
  MetersGained: number;
  TimeOfPossession: string; // Change the type to string
  territoryPct: string;
  tackles: number;
  penalties: number;
  points: number;
  home: string;
  away: string;
  color: string;
};

/*
// const formatTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
};
*/

const transformEventsToStats = (
  filteredTableEvents: MetricEvent[]
): StatItem[] => {
  const getAggregatedMetrics = (
    teamName: string,
    metricEvents: MetricEvent[]
  ): Group | undefined => {
    if (metricEvents.length === 0) {
      return undefined;
    }

    const territorySeconds = d3.sum(
      metricEvents,
      (d) => d.seconds_elapsed * d.territory
    );
    const possessionSeconds = d3.sum(
      metricEvents,
      (d) => d.seconds_elapsed * d.possession
    );
    const totalSeconds = d3.sum(metricEvents, (d) => d.seconds_elapsed);
    const totalTerrSeconds = d3.sum(
      metricEvents?.filter((event) => event.x !== 50),
      (d) => d.seconds_elapsed
    );
    return {
      group: teamName,
      MetersGained: Math.max(
        0,
        d3.sum(metricEvents, (d) => d.meters_gained)
      ),
      TimeOfPossession: `${((100 * possessionSeconds) / totalSeconds).toFixed(
        1
      )}%`,
      territoryPct: `${((100 * territorySeconds) / totalTerrSeconds).toFixed(
        1
      )}%`,
      tackles: d3.sum(metricEvents, (d) => d.tackles),
      penalties: d3.sum(metricEvents, (d) => d.penalties),
      points: d3.sum(metricEvents, (d) => d.points),
      home: d3.max(metricEvents, (d) => d.home_team) ?? "Home Team",
      away: d3.max(metricEvents, (d) => d.away_team) ?? "Away Team",
      color: d3.max(metricEvents, (d) => d.color) ?? "",
    };
  };

  const teamMetricEvents = d3.group(filteredTableEvents, (d) => d.team);

  const homeTeamName = filteredTableEvents?.[0]?.home_team;
  const homeTeamAggregatedMetrics = getAggregatedMetrics(
    homeTeamName,
    teamMetricEvents.get(homeTeamName) || []
  );

  const awayTeamName = filteredTableEvents?.[0]?.away_team;
  const awayTeamAggregatedMetrics = getAggregatedMetrics(
    awayTeamName,
    teamMetricEvents.get(awayTeamName) || []
  );

  const fields: { key: keyof Group; headerName: string }[] = [
    { key: "points", headerName: "Points" },
    { key: "MetersGained", headerName: "Meters Gained" },
    { key: "TimeOfPossession", headerName: "Possession %" },
    { key: "territoryPct", headerName: "Territory %" },
    { key: "tackles", headerName: "Tackles" },
    { key: "penalties", headerName: "Penalties" },
    { key: "color", headerName: "Color" },
    { key: "group", headerName: "TeamName" },
  ];

  return fields.map((field) => ({
    metric: field.headerName,
    home: String(homeTeamAggregatedMetrics?.[field.key]) ?? "",
    away: String(awayTeamAggregatedMetrics?.[field.key]) ?? "",
  }));
};

export const Analytics = () => {
  const [selectedFilterMatchId, setSelectedFilterMatchId] = useState("");

  const selectedFilterMatchIdInt = selectedFilterMatchId.length
    ? parseInt(selectedFilterMatchId)
    : undefined;

  const matchSelected = selectedFilterMatchId !== "";
  const homeTeam = useHomeTeam(selectedFilterMatchIdInt);
  const awayTeam = useAwayTeam(selectedFilterMatchIdInt);

  const actionTypes = useActionTypes();
  const metricEvents = useMatchMetricEvents(selectedFilterMatchIdInt);
  const matchOptions = useMatchOptions();
  const actionOptions = useActionOptions();

  const [selectedActions, setSelectedActions] = useState<SimpleSelectOption[]>(
    []
  );
  const [fileName, setFileName] = useState("");

  if (!matchOptions || !actionOptions) {
    return <CustomSpinner />;
  }

  const getFilteredTableEvents = (
    filterMatchId?: number,
    filterHalfNumbers?: number[]
  ): MetricEvent[] => {
    let filteredTableEvents = filterMatchId
      ? metricEvents?.filter((event) => event.match_id === filterMatchId)
      : metricEvents;
    if (filterHalfNumbers && filterHalfNumbers.length > 0) {
      filteredTableEvents = filteredTableEvents?.filter((event) =>
        filterHalfNumbers.includes(event.half_number)
      );
    }

    return filteredTableEvents ?? [];
  };

  const getFilteredPlotEvents = (
    filterMatchId?: number,
    filterActions?: SimpleSelectOption[]
  ): MetricEvent[] => {
    let filteredPlotEvents = filterMatchId
      ? metricEvents?.filter((event) => event.match_id === filterMatchId)
      : metricEvents;

    if (filterActions && filterActions.length > 0) {
      filteredPlotEvents = filteredPlotEvents?.filter((event) =>
        filterActions
          .map((filterAction) => filterAction.value)
          .includes(event.action)
      );
    }

    return filteredPlotEvents ?? [];
  };

  const filteredPlotEvents = getFilteredPlotEvents(
    selectedFilterMatchIdInt,
    selectedActions
  );

  const matchData = filteredPlotEvents?.filter(
    (event) => event.match_id === selectedFilterMatchIdInt
  );

  const availableActionNames = [
    actionTypes[DatabaseAction.phase],
    actionTypes[DatabaseAction.openPlayKick],
    actionTypes[DatabaseAction.kickoff],
    actionTypes[DatabaseAction.maulStart],
    actionTypes[DatabaseAction.kickRestart],
    actionTypes[DatabaseAction.tapRestart],
    actionTypes[DatabaseAction.receiveKick],
  ];

  const availableActionOptions =
    actionOptions?.filter((actionOption) =>
      availableActionNames.includes(actionOption.value)
    ) ?? [];

  const SPMatchData = matchData
    .filter((event) => event.possession === 1)
    .filter((event) => availableActionNames.includes(event.action));

  const minMG = d3.min(SPMatchData, (d) => d.meters_gained) || 0;
  const maxMG = d3.max(SPMatchData, (d) => d.meters_gained) || 100;

  const scatterData: Data[] = [
    {
      x: SPMatchData?.map((event) => event.x),
      y: SPMatchData?.map((event) => event.y),
      text: SPMatchData?.map(
        (event) =>
          "Starting X: " +
          event.x +
          "m<br>" +
          "Action: " +
          event.action +
          "<br>" +
          "Meters Gained: " +
          event.meters_gained +
          "m<br>" +
          "<i>Click to Watch Clip</i>"
      ),
      customdata: SPMatchData?.map((event) => event.event_id),
      mode: "markers",
      type: "scatter",
      marker: {
        size: SPMatchData?.map(
          (event) =>
            (maxMG * (event.meters_gained - minMG)) / (maxMG - minMG) + 1
        ),
        color: SPMatchData?.map((event) => event.color),
        opacity: 1.0,
        line: {
          color: "#000000",
          width: 1,
        },
      },
      hovertemplate: "%{text}<extra></extra>",
      transforms: [
        {
          type: "sort",
          target: "marker.size",
          order: "descending",
        },
      ],
    },
  ];
  // Calculate totals with the selected filterMatchId and selectedHalfNumbers
  const filteredTableEvents = getFilteredTableEvents(selectedFilterMatchIdInt);
  const stats = transformEventsToStats(filteredTableEvents);

  const filteredStats = stats
    .filter((row) => row.metric !== "TeamName")
    .filter((row) => row.metric !== "Color");

  return (
    <>
      <Stack>
        <SingleSelect
          label="Select a Match to View Stats and Analytics"
          widthFactor={3}
          value={String(selectedFilterMatchId)}
          setValue={(value) => {
            const matchOption = matchOptions?.find(
              (matchOption) => matchOption.id === value
            );
            setSelectedFilterMatchId(value);
            setFileName(matchOption?.value || "");
          }}
          options={matchOptions || []}
          none
        />
      </Stack>
      {metricEvents === undefined ? (
        <CustomSpinner />
      ) : (
        matchSelected &&
        homeTeam &&
        awayTeam && (
          <>
            <Box
              sx={{
                display: "flex",
                minHeight: "100px",
                justifyContent: "center",
                padding: "2em",
              }}
            >
              <Grid container spacing="1em">
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="h5"
                    sx={{ color: ColorPalette.blue }}
                    fontWeight="bold"
                  >
                    Match Stats
                  </Typography>
                  <div style={{ width: "100%" }}>
                    <PlotlyBarCharts
                      stats={filteredStats}
                      homeTeam={homeTeam}
                      awayTeam={awayTeam}
                    />
                  </div>
                  <CSVLink data={filteredTableEvents} filename={fileName}>
                    <Button variant="contained" sx={{ m: "2em" }}>
                      Download play-by-play data
                    </Button>
                  </CSVLink>
                </Grid>
                <Grid item xs={0} md={1}></Grid>
                <Grid item xs={12} md={7}>
                  <Typography
                    variant="h5"
                    sx={{ color: ColorPalette.blue, mb: "0.9em" }}
                    fontWeight="bold"
                  >
                    Meters Gained Explorer
                  </Typography>
                  {scatterData && <MatchScatterPlot data={scatterData} />}
                  <ChipSelect
                    label="Select Action(s)"
                    widthFactor={3}
                    options={availableActionOptions}
                    values={selectedActions}
                    setValues={setSelectedActions}
                  />
                </Grid>
              </Grid>
            </Box>
          </>
        )
      )}
    </>
  );
};
