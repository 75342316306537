import { MutableGridCol } from "./MutableGridCol";
import { GridValidRowModel } from "@mui/x-data-grid/models/gridRows";
import { GridValueFormatterParams, ValueOptions } from "@mui/x-data-grid";

export class SelectGridCol<
  Item extends GridValidRowModel
> extends MutableGridCol<Item> {
  type: string = "singleSelect";
  valueOptions: ValueOptions[];
  valueFormatter?: (params: GridValueFormatterParams) => string;

  constructor(
    field: Extract<keyof Item, string>,
    headerName: string,
    width: number,
    valueOptions: ValueOptions[],
    valueFormatter: (params: GridValueFormatterParams) => string = (params) => {
      return params.value.toString();
    }
  ) {
    super(field, headerName, width);
    this.valueOptions = valueOptions;
    this.valueFormatter = valueFormatter;
  }
}
