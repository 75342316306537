import * as React from "react";
import { GridColDef } from "@mui/x-data-grid";
import { XL_COLUMN, XXL_COLUMN } from "../utils/columnSizes";
import {
  Team,
  useCreateTeam,
  useDeleteTeam,
  useTeams,
  useUpdateTeam,
} from "../requests/teams";
import { isValidHexColor } from "../utils/isValidHexColor";
import { CustomSpinner } from "../components/CustomSpinner";
import { MutableGridCol } from "../components/CrudTable/columns/MutableGridCol";
import { ColorPickerGridCol } from "../components/CrudTable/columns/ColorPickerGridCol";
import { FullFeaturedCrudGrid } from "../components/CrudTable/FullFeaturedCrudGrid";
import { useMatches } from "../requests/matches";

const isValidTeam = (team?: Team) =>
  team && team.name.trim().length > 0 && isValidHexColor(team.color.trim());

export const Teams = () => {
  const teams = useTeams();
  const createTeam = useCreateTeam().mutateAsync;
  const updateTeam = useUpdateTeam().mutate;
  const deleteTeam = useDeleteTeam().mutate;

  const matches = useMatches();

  if (!teams || !matches) {
    return <CustomSpinner />;
  }

  const isMatchAssociatedWithTeam = (teamId: number): boolean =>
    matches
      .map((match) => match.home_team_id)
      .concat(matches.map((match) => match.away_team_id))
      .includes(teamId);

  const columns: GridColDef[] = [
    new MutableGridCol("name", "Team", XXL_COLUMN),
    new ColorPickerGridCol<Team>("color", "Color", XL_COLUMN),
  ];

  const getNewRow = (id: number): Team => ({ id, name: "", color: "#000000" });

  const getDeleteDialog = (team: Team) => `Delete ${team?.name}?`;

  return (
    <FullFeaturedCrudGrid
      crudRequests={{
        isStoredAsBlob: false,
        useCollection: useTeams,
        postSingleton: (team: Team) => createTeam(team),
        putSingleton: (team: Team) => updateTeam(team),
        deleteSingleton: (id: number) => deleteTeam(id),
      }}
      validators={{
        isValidCreateUpdate: isValidTeam,
        isValidDelete: (team: Team) => !isMatchAssociatedWithTeam(team.id),
      }}
      columns={columns}
      getNewRow={getNewRow}
      getDeleteDialog={getDeleteDialog}
      unableToDeleteTooltip="The team can not be deleted while associated with a match."
      itemName="team"
      initialSortModel={[{ field: "name", sort: "asc" }]}
      fieldToFocus="name"
    />
  );
};
