import { useQuery } from "@tanstack/react-query";
import { HttpRequestMethod, send } from "./utils/send";
import { useUser } from "./users";

export type MatchType = {
  id: number;
  name: string;
};

const matchTypes = async (): Promise<MatchType[]> =>
  send<MatchType[]>("/match-types", HttpRequestMethod.GET);

export const useMatchTypes = (): MatchType[] | undefined => {
  const user = useUser();
  return useQuery(["matchTypes"], matchTypes, {
    enabled: user?.permission_level_id !== undefined,
  }).data;
};
