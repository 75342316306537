import { useQuery } from "@tanstack/react-query";
import { PostTempIdResponse } from "./utils/PostTempIdResponse";
import { HttpRequestMethod, send } from "./utils/send";
import { useUser } from "./users";
import { getUseCreate } from "./utils/useCrud/getUseCreate";
import { getUseDelete } from "./utils/useCrud/getUseDelete";
import { getUseUpdate } from "./utils/useCrud/getUseUpdate";
import { useMatch } from "./matches";

export type Team = {
  id: number;
  name: string;
  color: string;
};

const sendGetTeams = (): Promise<Team[]> =>
  send<Team[]>("/teams", HttpRequestMethod.GET);

export const useTeams: () => Team[] | undefined = () => {
  const user = useUser();
  return useQuery(["teams"], sendGetTeams, {
    enabled: user?.permission_level_id !== undefined,
  }).data;
};

// useGetTeam must accept "" as a valid ID because it is possible to be set as much when a field input.
export const useGetTeam: () => (
  id: number | null | undefined | ""
) => Team | undefined = () => {
  const teams = useTeams();
  return (id: number | null | undefined | "") =>
    teams?.find((team) => team.id === id);
};

export const useHomeTeam: (matchId?: number) => Team | undefined = (
  matchId?: number
): Team | undefined => {
  const match = useMatch(matchId);
  const teams = useTeams();
  return teams?.find((team) => team.id === match?.home_team_id);
};

export const useAwayTeam: (matchId?: number) => Team | undefined = (
  matchId?: number
): Team | undefined => {
  const match = useMatch(matchId);
  const teams = useTeams();
  return teams?.find((team) => team.id === match?.away_team_id);
};

const sendPostTeam = (team: Team): Promise<PostTempIdResponse> =>
  send<PostTempIdResponse>("/teams", HttpRequestMethod.POST, team).then(
    (response) => ({
      ...response,
      temp_id: team.id,
    })
  );

const sendPutTeam = (team: Team): Promise<boolean> =>
  send<boolean>(`/teams/${team.id}`, HttpRequestMethod.PUT, team);

const sendDeleteTeam = (id: number): Promise<boolean> =>
  send<boolean>(`/teams/${id}`, HttpRequestMethod.DELETE);

export const useCreateTeam = getUseCreate(["teams"], sendPostTeam);

export const useUpdateTeam = getUseUpdate(["teams"], sendPutTeam);

export const useDeleteTeam = getUseDelete(["teams"], sendDeleteTeam);
