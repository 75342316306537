import React, { useEffect, useMemo, useRef } from "react";

import {
  MaterialReactTable,
  type MRT_ColumnDef,
  MRT_TableInstance,
} from "material-react-table";

import { darken, IconButton, Typography } from "@mui/material";

import { Team, useTeams } from "../requests/teams";

import { CustomSpinner } from "./CustomSpinner";
import { IS_LOCAL } from "../requests/utils/constants";
import { AnchorButton } from "./AnchorButton";
import { useTagsColumn } from "../hooks/column-definitions/useTagsColumn";
import { usePossessionTeamColumn } from "../hooks/column-definitions/usePossessionTeamColumn";
import DeleteIcon from "@mui/icons-material/Delete";
import assert from "assert";
import { LARGE_COLUMN, SMALL_COLUMN, XXL_COLUMN } from "../utils/columnSizes";
import { useEventsToEventGridRowModels } from "../hooks/useEventsToEventGridRowModels";
import { useCommentColumn } from "../hooks/column-definitions/useCommentColumn";
import { useActionTypes } from "../requests/actionTypes";
import { useMatches } from "../requests/matches";
import { useTagsQuery } from "../requests/tags";
import { DetailedEvent } from "../requests/matchEvents";

const DEFAULT_VIDEO_REVIEW_PAGE_SIZE = 3;
const DEFAULT_TRACKER_PAGE_SIZE = 5;
const highlightColor = "#99FF99";
const highlightColorHover = darken(highlightColor, 0.1);

type Props = {
  events: DetailedEvent[];
  currentEvent: DetailedEvent | null;
  deleteEvents?: (eventIds: number[]) => void;
  onClickTime: (row: EventGridRowModel) => void;
  pauseVideo: () => void;
  videoReviewMode?: boolean;
};

export type EventGridRowModel = DetailedEvent & {
  id: string;
  time: string;
  action: string;
  possession_team: Team;
  opposing_team: Team;
  match_date: string;
  tags: string[];
};

export const EventGrid = ({
  events,
  currentEvent,
  deleteEvents,
  onClickTime,
  pauseVideo,
  videoReviewMode,
}: Props) => {
  // WARNING: Deletion expects this to be within a single match, so this would break in video review mode.
  assert(!!videoReviewMode === (deleteEvents === undefined));

  const actionTypes = useActionTypes();
  const teams = useTeams();
  const matches = useMatches();
  const { isLoading: tagsIsLoading } = useTagsQuery();

  const mrtTableRef = useRef<MRT_TableInstance<EventGridRowModel>>(null);

  const defaultPageSize = videoReviewMode
    ? DEFAULT_VIDEO_REVIEW_PAGE_SIZE
    : DEFAULT_TRACKER_PAGE_SIZE;
  const pageSize =
    mrtTableRef.current?.getState().pagination.pageSize ?? defaultPageSize;

  const getCommentCell = useCommentColumn(pauseVideo);
  const getTagCell = useTagsColumn(pauseVideo);
  const getPossessionTeamCell = usePossessionTeamColumn();

  useEffect(() => {
    const currentEventIndex = events.findIndex(
      (event) =>
        event.match_id === currentEvent?.match_id &&
        event.match_event_id === currentEvent?.match_event_id
    );

    if (currentEventIndex === -1 || !mrtTableRef.current) {
      return;
    }

    mrtTableRef.current?.setPageIndex(() =>
      Math.floor(currentEventIndex / pageSize)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEvent, pageSize]);

  const eventsToEventGridRowModels = useEventsToEventGridRowModels();
  const rows = eventsToEventGridRowModels(events);

  const columns = useMemo<MRT_ColumnDef<EventGridRowModel>[]>(() => {
    const matchColumn: MRT_ColumnDef<EventGridRowModel> = {
      accessorKey: "match_id",
      header: "match",
      size: SMALL_COLUMN,
    };
    const eventColumn: MRT_ColumnDef<EventGridRowModel> = {
      accessorKey: "match_event_id",
      header: "event",
      size: SMALL_COLUMN,
    };
    const timeColumn: MRT_ColumnDef<EventGridRowModel> = {
      accessorKey: "time",
      header: "time",
      size: SMALL_COLUMN,
      Cell: ({ renderedCellValue, row }) => (
        <AnchorButton onClick={() => onClickTime(row.original)}>
          {renderedCellValue}
        </AnchorButton>
      ),
    };
    const actionColumn: MRT_ColumnDef<EventGridRowModel> = {
      accessorKey: "action", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
      header: "action",
      size: LARGE_COLUMN,
    };
    const possessionColumn: MRT_ColumnDef<EventGridRowModel> = {
      accessorFn: (row) => row.possession_team.name,
      id: "possession_team_basic",
      header: "team",
      size: LARGE_COLUMN,
    };
    const opposingTeamColumn: MRT_ColumnDef<EventGridRowModel> = {
      accessorFn: (row) => row.opposing_team.name,
      header: "opp team",
      size: LARGE_COLUMN,
    };
    const reversiblePossessionColumn: MRT_ColumnDef<EventGridRowModel> = {
      accessorFn: (row) => row.possession_team.name,
      id: "possession_team_swappable",
      header: "team",
      size: LARGE_COLUMN,
      Cell: ({ row }) => getPossessionTeamCell(row.original),
    };
    const commentColumn: MRT_ColumnDef<EventGridRowModel> = {
      accessorKey: "comment",
      header: "comment",
      size: SMALL_COLUMN,
      Cell: ({ row }) => getCommentCell(row.original),
    };
    const tagsColumn: MRT_ColumnDef<EventGridRowModel> = {
      accessorKey: "tags",
      header: "tags",
      size: XXL_COLUMN,
      Cell: ({ row }) => getTagCell(row.original),
    };
    const dateColumn: MRT_ColumnDef<EventGridRowModel> = {
      accessorKey: "match_date",
      header: "match date",
      size: LARGE_COLUMN,
    };

    return videoReviewMode
      ? [
          ...(IS_LOCAL ? [matchColumn, eventColumn] : []),
          timeColumn,
          actionColumn,
          tagsColumn,
          commentColumn,
          possessionColumn,
          opposingTeamColumn,
          dateColumn,
        ]
      : [
          ...(IS_LOCAL ? [eventColumn] : []),
          timeColumn,
          actionColumn,
          reversiblePossessionColumn,
          commentColumn,
          tagsColumn,
        ];
  }, [
    getCommentCell,
    getPossessionTeamCell,
    getTagCell,
    onClickTime,
    videoReviewMode,
  ]);

  if (!actionTypes || !teams || !matches || tagsIsLoading) {
    return <CustomSpinner />;
  }

  return (
    <MaterialReactTable
      tableInstanceRef={mrtTableRef}
      // enableColumnOrdering
      // enablePinning
      columns={columns}
      data={rows}
      // Prevents reset to the first page every time events are updated.
      autoResetPageIndex={false}
      // WARNING: Deletion expects this to be within a single match, so this will break in video review mode.
      enableRowSelection={!videoReviewMode}
      enableColumnActions={false}
      enableDensityToggle={false}
      enableSorting={false}
      enableFilters={false}
      enableHiding={false}
      enableTopToolbar={false}
      enableGlobalFilter={false}
      enableFullScreenToggle={false}
      renderBottomToolbarCustomActions={(table) => {
        // WARNING: Deletion expects this to be within a single match, so this will break in video review mode.
        const selectedMatchEventIds = table.table
          .getSelectedRowModel()
          .rows.map((row) => row.original.match_event_id);
        const onClickDelete = () => {
          deleteEvents?.(selectedMatchEventIds);
          table.table.resetRowSelection();
        };
        const numSelectedMatchEventIds = selectedMatchEventIds.length;
        return (
          <>
            {/* WARNING: Deletion expects this to be within a single match, so this will break in video review mode.*/}
            {!videoReviewMode && (
              <IconButton
                disabled={!numSelectedMatchEventIds}
                onClick={onClickDelete}
                color="error"
              >
                <DeleteIcon />
              </IconButton>
            )}
            {numSelectedMatchEventIds ? (
              <Typography fontSize="small">
                {numSelectedMatchEventIds} of {rows.length} row
                {rows.length > 1 && "s"} selected
              </Typography>
            ) : null}
          </>
        );
      }}
      initialState={{
        showColumnFilters: false,
        density: "compact",
        pagination: {
          pageSize: defaultPageSize,
          pageIndex: 0,
        },
      }}
      muiTablePaginationProps={{
        rowsPerPageOptions: [1, 3, 5, 10, 25, 50, 100],
      }}
      muiTableHeadCellProps={({ column }) => ({
        align: "center",
        sx: {
          align: "center",
          alignSelf: "center",
          textAlign: "center",
          m: 0,
          // don't think this does anything but would be nice to shrink this column
          px: column.id === "mrt-row-select" ? 0 : "0.25em",
          py: "0.25em",
        },
      })}
      muiTableBodyCellProps={({ cell }) => {
        return {
          sx: {
            m: 0,
            // don't think this does anything but would be nice to shrink this column
            px: cell.column.id === "mrt-row-select" ? 0 : "0.25em",
            py: 0,
            textAlign: "center",
            // border: 0.1,
            whiteSpace: "pre-wrap",
          },
        };
      }}
      muiSelectCheckboxProps={{
        size: "medium",
      }}
      muiSelectAllCheckboxProps={{
        size: "medium",
      }}
      muiTableBodyRowProps={({ row }) => ({
        sx:
          row.original.match_id === currentEvent?.match_id &&
          row.original.match_event_id === currentEvent?.match_event_id
            ? {
                backgroundColor: highlightColor,
                "&:hover td": {
                  backgroundColor: highlightColorHover,
                },
              }
            : {},
      })}
    />
  );
};

export default EventGrid;
