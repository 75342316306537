import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridSortDirection,
  GridToolbarContainer,
  useGridApiContext,
} from "@mui/x-data-grid";
import {
  getLargeRandomInt,
  isLargeRandomInt,
} from "../../utils/getLargeRandomInt";
import { GridValidRowModel } from "@mui/x-data-grid/models/gridRows";
import { MEDIUM_COLUMN, SMALL_COLUMN } from "../../utils/columnSizes";
import { IconButton } from "@mui/material";
import { CustomSpinner } from "../CustomSpinner";
import { GridRowParams } from "@mui/x-data-grid/models/params/gridRowParams";
import { useInterval } from "usehooks-ts";
import { REFRESH_INTERVAL_MS } from "../../utils/constants";
import { PostTempIdResponse } from "../../requests/utils/PostTempIdResponse";
import { AlertDialog } from "../AlertDialog";
import { IS_LOCAL } from "../../requests/utils/constants";
import { ImmutableGridCol } from "./columns/ImmutableGridCol";
import assert from "assert";
import { LockableButton } from "../LockableButton";
import { ConstGridCol } from "./columns/ConstGridCol";
import {
  OrganizationPermissions,
  UserPermission,
  useUser,
} from "../../requests/users";
import { LockableIconButton } from "../LockableIconButton";
import { getTypedEntries } from "../../utils/getTypedEntries";

export type CrudRequests<T> = {
  isStoredAsBlob: boolean;
  useCollection: () => T[] | undefined;
} & (
  | {
      isStoredAsBlob: false;
      postSingleton: (body: T) => Promise<PostTempIdResponse>;
      putSingleton: (body: T) => void;
      deleteSingleton: (id: number) => void;
    }
  | {
      isStoredAsBlob: true;
      putCollection: (body: T[]) => void;
    }
);

export type FullFeaturedCrudGridProps<T> = {
  crudRequests: CrudRequests<T>;
  validators: {
    // A string response designates the tooltip error message.
    isValidCreateUpdate: (
      item: T,
      prevItems: T[]
    ) => boolean | undefined | string;
    isValidDelete?: (item: T) => boolean;
  };
  columns: GridColDef[];
  getNewRow: (id: number) => T;
  getDeleteDialog: (item: T) => string;
  unableToDeleteTooltip?: string;
  itemName: string;
  lockKey?: keyof OrganizationPermissions;
  initialSortModel: { field: keyof T & string; sort: GridSortDirection }[];
  fieldToFocus: keyof T & string;
  simultaneousEdits?: boolean;
};

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
}

export const FullFeaturedCrudGrid = <
  T extends GridValidRowModel & { id: number }
>({
  crudRequests,
  validators: { isValidCreateUpdate, isValidDelete = () => true },
  columns,
  getNewRow,
  getDeleteDialog,
  unableToDeleteTooltip = "",
  itemName,
  lockKey,
  initialSortModel,
  fieldToFocus,
  simultaneousEdits = false,
}: FullFeaturedCrudGridProps<T>) => {
  const items = crudRequests.useCollection();
  const [rows, setRows] = useState<(T & { isNew?: boolean })[]>();
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  // We need to wait for the dialog to close before setting the alert dialog item to null, otherwise the dialog
  // will momentarily show `undefined`.
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const [alertDialogDeleteId, setAlertDialogDeleteId] = useState<number | null>(
    null
  );
  const alertDeleteItem = rows?.find((row) => row.id === alertDialogDeleteId);

  const updateBlob = (newRows: T[]) => {
    assert(crudRequests.isStoredAsBlob);
    crudRequests.putCollection(newRows as T[]);
  };

  useEffect(() => {
    if (!rows && !!items) {
      setRows(items);
    }
  }, [rows, items]);

  if (!rows) {
    return <CustomSpinner />;
  }

  const AddToolbar = ({ setRows, setRowModesModel }: EditToolbarProps) => {
    const user = useUser();

    if (!user) {
      return <CustomSpinner />;
    }

    const handleClick = () => {
      const id = getLargeRandomInt();
      setRows((oldRows) => [...oldRows, { ...getNewRow(id), isNew: true }]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: fieldToFocus },
      }));
    };

    // const isLocked = !locks
    //   ?.filter((lock) => lock.maxItemsCount > (items?.length ?? 0))
    //   .some((lock) =>
    //     user.organization_permissions?.includes(lock.maxItemsPermission)
    //   );

    let isLocked: boolean;
    if (lockKey === undefined) {
      isLocked = false;
    } else if (user.organization_permissions === null) {
      isLocked = true;
    } else {
      isLocked =
        (user.organization_permissions?.[lockKey] ?? Infinity) <=
        (items?.length ?? 0);
    }

    return (
      <GridToolbarContainer>
        <LockableButton
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleClick}
          isLocked={isLocked}
          lockMessage={`Adding another ${itemName} requires a QuickTap Pro account.`}
          minUserPermission={UserPermission.write}
        >
          Add {itemName}
        </LockableButton>
      </GridToolbarContainer>
    );
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const onEditClick = (id: GridRowId) => {
    setRowModesModel((prevState) => ({
      ...prevState,
      [id]: { mode: GridRowModes.Edit },
    }));
  };

  const onSaveClick = (id: GridRowId) => {
    setRowModesModel((prevState) => ({
      ...prevState,
      [id]: { mode: GridRowModes.View },
    }));
  };

  const onDeleteClick = (id: GridRowId) => {
    setAlertDialogDeleteId(Number(id));
    setIsAlertDialogOpen(true);
  };

  const onCancelClick = (id: GridRowId) => {
    setRowModesModel((prevState) => ({
      ...prevState,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    }));

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: GridRowModel<T>) => {
    if (isValidCreateUpdate(newRow as T, rows) !== true) {
      throw new Error("Invalid row");
    }

    // There are some values that aren't in the row because they aren't editable, but we need to post them.
    const rowWithAllValues = {
      ...items?.find((row) => row.id === newRow.id),
      ...newRow,
    };

    if (!crudRequests.isStoredAsBlob) {
      if (isLargeRandomInt(newRow.id)) {
        crudRequests.postSingleton(rowWithAllValues as T).then((response) => {
          setRows((prevState) =>
            prevState!.map((row) =>
              row.id === response.temp_id ? { ...row, id: response.id } : row
            )
          );
        });
      } else {
        crudRequests.putSingleton(rowWithAllValues as T);
      }
    } else {
      const newRows = rows.map((row) => (row.id === newRow.id ? newRow : row));
      updateBlob(newRows);
    }

    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const SaveButton = ({ id, row }: GridRowParams) => {
    const gridApiRef = useGridApiContext();
    const [updatedRow, setUpdatedRow] = useState<T>(row as T);

    // If we don't manually refresh the state, the save button will not know when to enable/disable.
    useInterval(
      () =>
        setUpdatedRow(gridApiRef.current.getRowWithUpdatedValues(id, "") as T),
      REFRESH_INTERVAL_MS
    );

    const validCreate = isValidCreateUpdate(updatedRow, rows);

    return (
      <LockableIconButton
        onClick={() => onSaveClick(id)}
        color="primary"
        // Must explicitly check that it is not true because validCreate can be a string lock message.
        isLocked={validCreate !== true}
        lockMessage={validCreate ? (validCreate as string) : ""}
        // lockMessage="HELLO WORLD"
      >
        <SaveIcon />
      </LockableIconButton>
    );
  };

  const isRowLockedByNewIdSize = (value: string | number): boolean =>
    !crudRequests.isStoredAsBlob && isLargeRandomInt(value);

  const isEditingLocked =
    !simultaneousEdits &&
    Object.entries(rowModesModel).filter(
      ([, rowMode]) => rowMode.mode === GridRowModes.Edit
    ).length > 0;

  const isCellEditable = (params: GridCellParams) => {
    const constGridCols = columns
      .filter((column) => column instanceof ConstGridCol)
      .map((column) => column.field);
    const isConstGridCol = constGridCols.includes(params.field);

    const isOtherRowEditing = getTypedEntries(rowModesModel).some(
      ([id, rowMode]) =>
        Number(id) !== Number(params.id) && rowMode.mode === GridRowModes.Edit
    );

    return !isConstGridCol && !isOtherRowEditing;
  };

  const idColumn: GridColDef[] = [
    // If ever displaying team ID to real users, this must be modified, as we initially create a random ID.
    ...(IS_LOCAL ? [new ImmutableGridCol("id", "ID", SMALL_COLUMN)] : []),
  ];

  columns = idColumn.concat(columns).concat([
    {
      field: "actions",
      type: "actions",
      headerName: "",
      width: MEDIUM_COLUMN,
      getActions: (props) => {
        const { id, row } = props;
        return rowModesModel[id]?.mode === GridRowModes.Edit
          ? [
              <SaveButton {...props} />,
              <IconButton onClick={() => onCancelClick(id)} color="primary">
                <CancelIcon />
              </IconButton>,
            ]
          : [
              <LockableIconButton
                lockMessage={
                  isEditingLocked
                    ? "Cannot edit while another row is being edited."
                    : ""
                }
                isLocked={isEditingLocked || isRowLockedByNewIdSize(id)}
                onClick={() => onEditClick(id)}
                color="primary"
                minUserPermission={UserPermission.write}
              >
                <EditIcon />
              </LockableIconButton>,

              <LockableIconButton
                lockMessage={unableToDeleteTooltip}
                isLocked={isRowLockedByNewIdSize(id) || !isValidDelete(row)}
                tooltipProps={{
                  enterDelay: 500,
                  followCursor: true,
                  disableHoverListener: isValidDelete(row),
                }}
                onClick={() => onDeleteClick(row.id)}
                color="error"
                minUserPermission={UserPermission.write}
              >
                <DeleteIcon />
              </LockableIconButton>,
            ];
      },
    },
  ]);

  return (
    <Box
      sx={{
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <AlertDialog
        message={getDeleteDialog(alertDeleteItem as T)}
        isOpen={isAlertDialogOpen}
        onCloseDialog={() => {
          setIsAlertDialogOpen(false);
          // We need to wait for the dialog to close before setting the alert dialog item to null, otherwise the dialog
          // will momentarily show `undefined`.
          setTimeout(() => setAlertDialogDeleteId(null), 100);
        }}
        onConfirmDialog={() => {
          assert(alertDialogDeleteId !== null);
          if (!crudRequests.isStoredAsBlob) {
            crudRequests.deleteSingleton(alertDialogDeleteId);
          } else {
            updateBlob(rows.filter((row) => row.id !== alertDialogDeleteId));
          }
          setRows((prevState) =>
            prevState?.filter((row) => row.id !== alertDialogDeleteId)
          );
        }}
      />
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={(error) => {}}
        slots={{
          toolbar: AddToolbar,
          // noRowsOverlay: () => <></>,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        hideFooter={true}
        // Prevent editing of fields that should only be set on row creation.
        isCellEditable={isCellEditable}
        initialState={{
          sorting: {
            sortModel: initialSortModel,
          },
        }}
        getRowHeight={() => "auto"}
        sx={{
          m: 1,
          [`& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within`]: {
            outline: "none !important",
          },
          [`& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within`]:
            {
              outline: "none",
            },
        }}
      />
    </Box>
  );
};
