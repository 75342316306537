import { TooltipChip, TooltipChipProps } from "./TooltipChip";
import { ColorPalette } from "../utils/constants";
import { lighten } from "@mui/material";
import React from "react";

type Props = TooltipChipProps & {
  isSelected: boolean;
  key: number;
};

export const TagChip = ({ isSelected, ...props }: Props) => (
  <span key={props.key}>
    {isSelected ? (
      <TooltipChip
        {...props}
        chipProps={{
          sx: {
            backgroundColor: lighten(ColorPalette.gray, 0.5),
            boxShadow: "gray 1px 1.5px",
          },
        }}
      />
    ) : (
      <TooltipChip {...props} onDelete={undefined} />
    )}
  </span>
);
