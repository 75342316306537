import { UserPermission, useUser } from "../requests/users";

export const usePermissionLockAndMessage = (
  lockMessage: string,
  isLocked: boolean,
  minUserPermission?: UserPermission
): { permissionMessage: string; permissionLock: boolean } => {
  const user = useUser();
  let permissionMessage = lockMessage;
  let permissionLock = isLocked;
  if (minUserPermission && user?.permission_level_id !== undefined) {
    if (
      minUserPermission === UserPermission.owner &&
      user.permission_level_id !== UserPermission.owner
    ) {
      permissionMessage = "You must have owner access to use feature.";
      permissionLock = true;
    } else if (
      minUserPermission === UserPermission.write &&
      ![UserPermission.owner, UserPermission.write].includes(
        user.permission_level_id
      )
    ) {
      permissionMessage = "You must have write access to use this feature.";
      permissionLock = true;
    } else if (
      minUserPermission === UserPermission.read &&
      ![
        UserPermission.owner,
        UserPermission.write,
        UserPermission.read,
      ].includes(user.permission_level_id)
    ) {
      permissionMessage = "You must have read access to use this feature.";
      permissionLock = true;
    }
  }
  return { permissionMessage, permissionLock };
};
