import { Modal, ModalProps } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
};

export const CustomModal = (props: ModalProps) => (
  <Modal
    {...props}
    slotProps={{
      backdrop: {
        style: {
          // backdropFilter: "blur(2px)",
          opacity: 0.4,
        },
      },
    }}
  >
    <Box sx={style}>{props.children}</Box>
  </Modal>
);
