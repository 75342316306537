import image from "../assets/rugbyPitch.jpg";

const GRID_START_X = 68;
const GRID_START_Y = 38;
const GRID_END_X = 545;
const GRID_END_Y = 375;

const BASE_IMAGE_WIDTH = 612;
const BASE_IMAGE_HEIGHT = 409;

const X_MAX_COORDINATE = 100;
const Y_MAX_COORDINATE = 70;

type Pitch = {
  image: string;
  getXCoordinate: (xOffset: number, offsetWidth: number) => number;
  getYCoordinate: (yOffset: number, offsetHeight: number) => number;
  getPixelXCoordinate: (xCoordinate: number, offsetWidth: number) => number;
  getPixelYCoordinate: (yCoordinate: number, offsetHeight: number) => number;
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;
};

const rugbyPitch: Pitch = {
  image,
  getXCoordinate: (xOffset: number, offsetWidth: number): number => {
    const relativeGridStartX = (GRID_START_X * offsetWidth) / BASE_IMAGE_WIDTH;
    const relativeGridEndX = (GRID_END_X * offsetWidth) / BASE_IMAGE_WIDTH;
    return (
      ((xOffset - relativeGridStartX) * X_MAX_COORDINATE) /
      (relativeGridEndX - relativeGridStartX)
    );
  },
  getYCoordinate: (yOffset: number, offsetHeight: number): number => {
    const relativeGridStartY =
      (GRID_START_Y * offsetHeight) / BASE_IMAGE_HEIGHT;
    const relativeGridEndY = (GRID_END_Y * offsetHeight) / BASE_IMAGE_HEIGHT;
    return (
      Y_MAX_COORDINATE -
      ((yOffset - relativeGridStartY) * Y_MAX_COORDINATE) /
        (relativeGridEndY - relativeGridStartY)
    );
  },
  getPixelXCoordinate: (xCoordinate: number, offsetWidth: number): number => {
    const relativeGridStartX = (GRID_START_X * offsetWidth) / BASE_IMAGE_WIDTH;
    const relativeGridEndX = (GRID_END_X * offsetWidth) / BASE_IMAGE_WIDTH;
    return (
      (xCoordinate * (relativeGridEndX - relativeGridStartX)) /
        X_MAX_COORDINATE +
      relativeGridStartX
    );
  },
  getPixelYCoordinate: (yCoordinate: number, offsetHeight: number): number => {
    const relativeGridStartY =
      (GRID_START_Y * offsetHeight) / BASE_IMAGE_HEIGHT;
    const relativeGridEndY = (GRID_END_Y * offsetHeight) / BASE_IMAGE_HEIGHT;
    return (
      (yCoordinate * (relativeGridEndY - relativeGridStartY)) /
        Y_MAX_COORDINATE +
      relativeGridStartY
    );
  },
  xMin: -10,
  xMax: 110,
  yMin: 0,
  yMax: 70,
};

export default rugbyPitch;
