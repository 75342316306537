import { useMatchTypes } from "../../requests/matchTypes";
import { useGetTeam, useTeams } from "../../requests/teams";
import { AllDatabaseActions } from "../../business-logic/ActionBehavior/Action";
import { getMatchName } from "../../utils/getMatchName";
import { useActionTypes } from "../../requests/actionTypes";
import { useMatches } from "../../requests/matches";
import { useTags } from "../../requests/tags";

export type SimpleSelectOption = {
  id: string;
  value: string;
};

export const useTeamOptions: () => SimpleSelectOption[] | undefined = () =>
  useTeams()
    ?.map((team) => ({
      id: team.id.toString(),
      value: team.name,
    }))
    .sort((a, b) => (a.value < b.value ? -1 : 1));

export const useMatchOptions: () => SimpleSelectOption[] | undefined = () => {
  const getTeam = useGetTeam();

  return useMatches()
    ?.sort((a, b) => (a.start_time > b.start_time ? -1 : 1))
    ?.map((match) => {
      const dateString = new Date(match.start_time).toLocaleDateString(
        "en-US",
        {
          year: "numeric",
          month: "short",
          day: "numeric",
        }
      );
      return {
        id: match.id.toString(),
        value: getMatchName(
          getTeam(match.home_team_id)?.name,
          getTeam(match.away_team_id)?.name,
          dateString
        ),
      };
    });
};

export const useMatchTypeOptions: () => SimpleSelectOption[] | undefined = () =>
  useMatchTypes()?.map((matchType) => ({
    id: matchType.id.toString(),
    value: matchType.name,
  }));

export const useTagOptions: () => SimpleSelectOption[] | undefined = () =>
  useTags()
    ?.map((tag) => ({
      id: tag.id.toString(),
      value: tag.name,
    }))
    .sort((a, b) => (a.value < b.value ? -1 : 1));

export const useActionOptions: () => SimpleSelectOption[] | undefined = () => {
  const actionTypes = useActionTypes();
  return AllDatabaseActions.map(
    (action) =>
      ({
        id: action.toString(),
        value: actionTypes[action],
      } as SimpleSelectOption)
  ).sort((a, b) => (a.value < b.value ? -1 : 1));
};
