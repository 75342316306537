import { GridColumnHeaderParams } from "@mui/x-data-grid";
import * as React from "react";
import { ReactNode } from "react";
import { GridValidRowModel } from "@mui/x-data-grid/models/gridRows";
import { GridBaseColDef } from "@mui/x-data-grid/models/colDef/gridColDef";

export class ImmutableGridCol<Item extends GridValidRowModel>
  implements GridBaseColDef<Item>
{
  field: Extract<keyof Item, string>;
  headerName: string;
  width: number;
  renderHeader: (params: GridColumnHeaderParams<Item>) => ReactNode;

  constructor(
    field: Extract<keyof Item, string>,
    headerName: string,
    width: number
  ) {
    this.field = field;
    this.headerName = headerName;
    this.width = width;
    this.renderHeader = (params) => <strong>{params.colDef.headerName}</strong>;
  }
}
