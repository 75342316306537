export const DEV_API_DOMAIN = "https://api.dev.getquicktap.com";
// export const DEV_API_DOMAIN = "https://api.dev.getquicktap.com";
// TEST_API_DOMAIN should not be a real domain so that it fails if it is not mocked.
export const TEST_API_DOMAIN = "https://api.test.com";
export const STAGING_API_DOMAIN = "https://api.staging.getquicktap.com";
export const PROD_API_DOMAIN = "https://api.getquicktap.com";

let apiDomain;
let isLocal = false;
let isProd = false;
switch (process.env.NODE_ENV) {
  case "development":
    apiDomain = DEV_API_DOMAIN;
    isLocal = true;
    break;
  case "test":
    apiDomain = TEST_API_DOMAIN;
    break;
  default:
    if (window.location.hostname === "app.getquicktap.com") {
      apiDomain = PROD_API_DOMAIN;
      isProd = true;
    } else {
      apiDomain = STAGING_API_DOMAIN;
    }
}

export const API_DOMAIN = apiDomain;

// THe only difference between local and staging/prod environments should be the addition of tools for debugging.
export const IS_LOCAL = isLocal;

// The only differences in user experience on prod should be related to tools like Stripe keys.
export const IS_PROD = isProd;

export const HTTP_STATUS_NO_CONTENT = 204;
