import { MutableGridCol } from "./MutableGridCol";
import { GridValidRowModel } from "@mui/x-data-grid/models/gridRows";
import { GridValueSetterParams } from "@mui/x-data-grid";
import { Dispatch, SetStateAction } from "react";

export class DateGridCol<
  Item extends GridValidRowModel
> extends MutableGridCol<Item> {
  type: string = "date";
}

export class DateTimeGridCol<
  Item extends GridValidRowModel
> extends MutableGridCol<Item> {
  type: string = "dateTime";
  valueGetter: (params: GridValueSetterParams<Item>) => Date;
  valueSetter: (params: GridValueSetterParams<Item, Date>) => Item;

  // valueParser: (value: Date) => string;

  constructor(
    field: Extract<keyof Item, string>,
    headerName: string,
    width: number,
    rowEdits: { [p: string]: Item },
    setRowEdits: Dispatch<SetStateAction<{ [p: string]: Item }>>
  ) {
    super(field, headerName, width);
    this.valueGetter = (params: GridValueSetterParams<Item>) => {
      if (params.value.getTime() === new Date(1900, 1).getTime()) {
        return null;
      }
      return params.value;
    };

    this.valueSetter = (params: GridValueSetterParams<Item, Date>) => {
      const value = params.value;
      if (params.value === undefined) {
        return params.row;
      }

      const currentValue = rowEdits?.[params.row.id]?.[field];
      // This check is necessary to prevent a continuous state refresh.
      if (currentValue && currentValue.getTime() !== value?.getTime()) {
        // TODO: React doesn't like us updating another component in here
        setRowEdits((prevState) => {
          const newState = { ...prevState };
          // @ts-ignore TODO: not sure why, but error `TS2322: Type number is not assignable to type Item[Extract<keyof Item, string>]`
          newState[params.row.id][field] = value;
          return newState;
        });
      }

      return {
        ...params.row,
        [field]: value,
      };
    };
  }
}
