import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useUser } from "./users";
import { HttpRequestMethod, send } from "./utils/send";
import { DetailedEvent, useUpdateDetailedEvent } from "./matchEvents";
import assert from "assert";
import { sendDeleteEventTag, sendPutEventTag } from "./eventTags";

export type Tag = {
  id: number;
  name: string;
  // TODO: not actually optional, but we don't use it and don't set it when updating ReactQuery state
  organization_id?: number;
};

export const sendGetTags = (): Promise<Tag[]> =>
  send<Tag[]>("/tags", HttpRequestMethod.GET);

export const useTagsQuery = () => {
  const user = useUser();
  return useQuery(["tags"], sendGetTags, {
    enabled: user?.permission_level_id !== undefined,
  });
};

export const useTags = (): Tag[] => useTagsQuery()?.data ?? [];

// This adds a new tag to the list of tag options in the ReactQuery state.
export const useAddTagOptionToState = (): ((tag: string) => void) => {
  const queryClient = useQueryClient();
  return (newTagName: string) =>
    queryClient.setQueryData(["tags"], (prevTags: Tag[] = []) => {
      queryClient.cancelQueries(["tags"]).then(() => {});
      const newTag: Tag = {
        id: Math.max(...prevTags.map((tag) => tag.id)) + 1,
        name: newTagName,
      };
      return prevTags.map((tag) => tag.name).includes(newTag.name)
        ? prevTags
        : [...prevTags, newTag];
    });
};

export const useAddTagsSync = () => {
  const addEventTag = useUpdateDetailedEvent(async ({ detailedEvent, tags }) =>
    tags?.length
      ? Promise.all(
          tags.map(
            async (tag) =>
              await sendPutEventTag(
                detailedEvent.match_id,
                detailedEvent.match_event_id,
                tag!
              )
          )
        )
      : Promise.resolve()
  );

  return (detailedEvent: DetailedEvent, tags: string[]) =>
    addEventTag.mutate({
      detailedEvent: {
        ...detailedEvent,
        tags: Array.from(new Set([...detailedEvent.tags, ...tags])),
      },
      tags,
    });
};

export const useDeleteTagSync = () => {
  const deleteTag = useUpdateDetailedEvent(async ({ detailedEvent, tags }) => {
    assert(tags?.length === 1);
    await sendDeleteEventTag(
      detailedEvent.match_id,
      detailedEvent.match_event_id,
      tags[0]
    );
  });

  return (detailedEvent: DetailedEvent, tag: string) =>
    deleteTag.mutate({
      detailedEvent: {
        ...detailedEvent,
        tags: detailedEvent.tags.filter((_tag) => _tag !== tag),
      },
      tags: [tag],
    });
};
