import { Dayjs } from "dayjs";

export const formatDateForRequest = (date: Dayjs | null): string | null => {
  try {
    return date?.toISOString()?.substring(0, 10) ?? null;
  } catch (error) {
    console.info("Error formatting date for request", error);
    return null;
  }
};
