import { getColorTokens } from "../utils/getColorTokens";
import { Button, ButtonProps } from "@mui/material";

type Props = Omit<ButtonProps, "color"> & {
  color: string;
};

export const ColorButton = ({ color, ...buttonProps }: Props) => {
  const colorTokens = getColorTokens(color);
  return (
    <Button
      {...buttonProps}
      sx={{
        background: colorTokens.main,
        "&.Mui-disabled": {
          backgroundColor: colorTokens.light,
        },
        "&:hover": {
          backgroundColor: colorTokens.dark,
        },
        marginX: 0.25,
        marginY: 0.5,
      }}
    >
      {buttonProps.children}
    </Button>
  );
};
