import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";
import { EventFilters } from "../components/VideoReview/VideoReviewForm";
import { formatDateForRequest } from "../utils/formatDateForRequest";
import { HttpRequestMethod, send } from "./utils/send";
import assert from "assert";
import { DatabaseAction } from "../business-logic/ActionBehavior/Action";

export const getFilteredEventFilters = (
  filters: Partial<EventFilters>
): [string, any][] => {
  const { startDate, endDate, ...otherFilters } = filters;
  const transformedFilters = {
    ...otherFilters,
    startDate: formatDateForRequest(startDate ?? null),
    endDate: formatDateForRequest(endDate ?? null),
  };

  // Filter out empty string or empty list values.
  return Object.entries(transformedFilters).filter(
    ([_key, value]) => (value?.toString() ?? "") !== ""
  );
};

export type GtEvent = {
  // id: number;
  match_id: number;
  match_event_id: number;
  action_type_id: DatabaseAction;
  begin_team_id: number;
  end_team_id: number;
  event_time_in_ms: number;
  loc_x: number;
  loc_y: number;
  is_deleted: boolean;
};

export type DetailedEvent = GtEvent & {
  comment?: string;
  lead_time_in_ms?: number;
  tags: string[];
};

export const sendGetFilteredDetailedEvents = async (
  filters: Partial<EventFilters>
): Promise<DetailedEvent[]> => {
  const filteredEventFilters = getFilteredEventFilters(filters);
  const queryString = filteredEventFilters
    .map(([key, value]) => encodeURI(`${key}=${value}`))
    .join("&");

  return send<DetailedEvent[]>(
    `/detailed-events${queryString ? "?" : ""}${queryString}`,
    HttpRequestMethod.GET
  );
};

export const sendGetMatchDetailedEvents = async (
  matchId: number
): Promise<DetailedEvent[]> =>
  send<DetailedEvent[]>(
    `/matches/${matchId}/detailed-events`,
    HttpRequestMethod.GET
  );

export const useQueryFilteredDetailedEvents = (
  filters: Partial<EventFilters> | undefined
): UseQueryResult<DetailedEvent[]> => {
  return useQuery(["detailedEvent", "filtered", filters], () =>
    filters ? sendGetFilteredDetailedEvents(filters) : null
  );
};

export const useQueryMatchDetailedEvents = (
  matchId: number
): UseQueryResult<DetailedEvent[]> =>
  useQuery(["detailedEvent", "match", matchId], () =>
    sendGetMatchDetailedEvents(matchId)
  );

export const useUpdateDetailedEvent = <TData>(
  // Specifies the backend request to send to update the event.
  mutationFn: (variables: {
    detailedEvent: DetailedEvent;
    tags?: string[];
  }) => Promise<TData>,
  onSuccess?: (data: TData) => void
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: mutationFn,
    onMutate: async ({ detailedEvent }) => {
      queryClient
        .cancelQueries(["detailedEvent", "match", detailedEvent.match_id])
        .then(() => {
          return queryClient.setQueriesData(
            ["detailedEvent", "match", detailedEvent.match_id],
            (prevDetailedEvents: DetailedEvent[] = []) => {
              // I'm not sure why, but if we don't use optional chaining, this can cause the following error:
              // TypeError: Cannot read properties of null (reading 'map')
              return prevDetailedEvents?.map((prevDetailedEvent) =>
                prevDetailedEvent.match_id === detailedEvent.match_id &&
                prevDetailedEvent.match_event_id ===
                  detailedEvent.match_event_id
                  ? detailedEvent
                  : prevDetailedEvent
              );
            }
          );
        });
    },
    onSuccess: onSuccess,
  });
};

export type PostMatchEventResponse = {
  match_id: number;
  match_event_id: number;
  temp_match_event_id: number;
};

// TODO: Despite just being called sendPostMatchEvent(), this will actually add tags as well.
export const sendPostMatchEvent = (
  event: DetailedEvent
): Promise<PostMatchEventResponse> =>
  send<PostMatchEventResponse>(
    `/matches/${event.match_id}/events`,
    HttpRequestMethod.POST,
    event
  ).then((response) => ({
    ...response,
    temp_match_event_id: event.match_event_id,
  }));

export const sendPostMatchEvents = async (
  events: GtEvent[]
): Promise<boolean> => {
  const matchId = events[0].match_id;
  assert(events.every((e) => e.match_id === matchId));
  return send(`/matches/${matchId}/events`, HttpRequestMethod.POST, events);
};

export const useCreateDetailedEvent = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (detailedEvent: DetailedEvent) =>
      sendPostMatchEvent(detailedEvent),
    onMutate: async (detailedEvent) =>
      queryClient
        .cancelQueries(["detailedEvent", "match", detailedEvent.match_id])
        .then(() =>
          queryClient.setQueryData(
            ["detailedEvent", "match", detailedEvent.match_id],
            (prevDetailedEvents: DetailedEvent[] = []) => {
              return [...prevDetailedEvents, detailedEvent];
            }
          )
        ),
    onSuccess: (postMatchEventResponse) => {
      if (!postMatchEventResponse) {
        alert("Error creating event. Please refresh and try again.");
        return;
      }
      // This updates the temporary match event id with the real one.
      queryClient.setQueryData(
        ["detailedEvent", "match", postMatchEventResponse.match_id],
        (prevDetailedEvents: DetailedEvent[] = []) =>
          prevDetailedEvents.map((prevDetailedEvent) =>
            prevDetailedEvent.match_event_id ===
            postMatchEventResponse.temp_match_event_id
              ? {
                  ...prevDetailedEvent,
                  match_event_id: postMatchEventResponse.match_event_id,
                }
              : prevDetailedEvent
          )
      );
    },
  });
};

export const sendDeleteMatchEvents = async (
  matchId: number,
  matchEventIds: number[]
): Promise<boolean> =>
  send<boolean>(
    `/matches/${matchId}/events`,
    HttpRequestMethod.DELETE,
    matchEventIds
  );

export const useDeleteDetailedEvent = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      matchId,
      matchEventId,
    }: {
      matchId: number;
      matchEventId: number;
    }) => sendDeleteMatchEvents(matchId, [matchEventId]),
    onMutate: async ({ matchId, matchEventId }) =>
      queryClient
        .cancelQueries(["detailedEvent", "match", matchId])
        .then(() =>
          queryClient.setQueriesData(
            ["detailedEvent", "match", matchId],
            (prevDetailedEvents: DetailedEvent[] = []) =>
              prevDetailedEvents.filter(
                (prevDetailedEvent) =>
                  prevDetailedEvent.match_id !== matchId ||
                  prevDetailedEvent.match_event_id !== matchEventId
              )
          )
        ),
  });
};
