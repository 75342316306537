import { Data } from "plotly.js-basic-dist";
import Plotly from "plotly.js-basic-dist";
import image from "../../assets/rugbyPitch.jpg";
import React from "react";
import createPlotlyComponent from "react-plotly.js/factory";

type MatchScatterPlotProps = {
  data: Data[];
};

export const MatchScatterPlot = ({ data }: MatchScatterPlotProps) => {
	const Plot = createPlotlyComponent(Plotly);
  return (
    <Plot
			data={data}
			layout={{
				width: 765,
				height: 511,
				xaxis: {
					range: [-14, 114],
					showgrid: false,
					visible: false,
					fixedrange: true
				},
				yaxis: {
					range: [-7.5, 77.5],
					showgrid: false,
					visible: false,
					fixedrange: true
				},
				margin: { t: 0, b: 0, l: 0, r: 0 },
				images: [
					{
						source: image,
						x: -14,
						y: -7.5,
						xref: "x",
						yref: "y",
						xanchor: "left",
						yanchor: "bottom",
						sizex: 128,
						sizey: 85,
						layer: "below",
					},
				],
			}}
			config={{ displayModeBar: false, }}
			onClick={(data) => {
				if (data.points.length === 1) {
					window.open(`${window.location.origin}/video-review?eventId=${data.points[0].customdata}`,"_blank")
				}
			}}
    />
  );
};
