import { getWidth, SingleSelect } from "../simple-select/SimpleSelect";
import { Box, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { PitchSlider } from "./PitchSlider";
import rugbyPitch from "../rugbyPitch";
import { EventFilters } from "./VideoReviewForm";
import {
  SimpleSelectOption,
  useActionOptions,
  useMatchTypeOptions,
  useTagOptions,
  useTeamOptions,
} from "../simple-select/simpleSelectOptions";
import { ChipSelect } from "../simple-select/ChipSelect";
import dayjs, { Dayjs } from "dayjs";

type Props = {
  eventFilters: EventFilters;
  setEventFilter: (
    key: keyof EventFilters,
    previousIds: (number | string)[],
    options: SimpleSelectOption[],
    getValues: (prevState: SimpleSelectOption[]) => SimpleSelectOption[]
  ) => void;
  setEventFilters: (updatedFilters: Partial<EventFilters>) => void;
};

export const AdvancedFilters = ({
  eventFilters,
  setEventFilter,
  setEventFilters,
}: Props) => {
  const teamOptions = useTeamOptions();
  const matchTypeOptions = useMatchTypeOptions();
  const tagOptions = useTagOptions();
  const actionOptions = useActionOptions();

  if (!teamOptions || !matchTypeOptions || !tagOptions || !actionOptions) {
    return null;
  }

  const setTeam1Value = (value: string) => {
    // If both team1 and team2 are selected, reset the possession team if it is not one of the selected teams.
    const possessionTeamId =
      eventFilters.possessionTeamId === value ||
      eventFilters.possessionTeamId === eventFilters.team2Id
        ? eventFilters.possessionTeamId
        : "";

    setEventFilters({
      team1Id: value,
      possessionTeamId,
    });
  };

  const setTeam2Value = (value: string) => {
    // If both team1 and team2 are selected, reset the possession team if it is not one of the selected teams.
    const possessionTeamId =
      eventFilters.possessionTeamId === value ||
      eventFilters.possessionTeamId === eventFilters.team1Id
        ? eventFilters.possessionTeamId
        : "";
    setEventFilters({
      team2Id: value,
      possessionTeamId,
    });
  };

  // I'm not sure why this is necessary, but modfiying an existing date fails if the value is not wrapped by this.
  const dayjsIfExists = (date: Dayjs | null) => (date ? dayjs(date) : null);
  const dayjsIfValid = (date: Dayjs | null) => (date?.isValid() ? date : null);

  return (
    <Box>
      <SingleSelect
        value={eventFilters.team1Id}
        setValue={setTeam1Value}
        label="Team 1"
        options={teamOptions}
        none
        widthFactor={1.2}
        disabled={eventFilters.matchId !== ""}
      />
      <SingleSelect
        value={eventFilters.team2Id}
        setValue={setTeam2Value}
        label="Team 2"
        options={teamOptions}
        none
        widthFactor={1.2}
        disabled={eventFilters.matchId !== ""}
      />
      <SingleSelect
        value={eventFilters.matchTypeId}
        setValue={(value) => setEventFilters({ matchTypeId: value })}
        label="Match Type"
        options={matchTypeOptions}
        none
        widthFactor={1.2}
      />
      <TextField
        value={eventFilters.commentRegex}
        onChange={(e) => setEventFilters({ commentRegex: e.target.value })}
        label="Comment Regex"
        sx={{ m: 1, width: getWidth(1.2) }}
      />
      <br />
      <ChipSelect
        label="Previous Actions"
        widthFactor={2.5}
        options={actionOptions}
        values={eventFilters.previousActionIds.map(
          (actionId) =>
            actionOptions.find(
              (actionOption) => actionId === actionOption.id
            ) as SimpleSelectOption
        )}
        setValues={(
          _getValues: (prevState: SimpleSelectOption[]) => SimpleSelectOption[]
        ) =>
          setEventFilter(
            "previousActionIds",
            eventFilters.previousActionIds,
            actionOptions,
            _getValues
          )
        }
      />
      <ChipSelect
        label="Next Actions"
        widthFactor={2.5}
        options={actionOptions}
        values={eventFilters.nextActionIds.map(
          (actionId) =>
            actionOptions.find(
              (actionOption) => actionId === actionOption.id
            ) as SimpleSelectOption
        )}
        setValues={(
          _getValues: (prevState: SimpleSelectOption[]) => SimpleSelectOption[]
        ) =>
          setEventFilter(
            "nextActionIds",
            eventFilters.nextActionIds,
            actionOptions,
            _getValues
          )
        }
      />
      <br />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          sx={{ m: 1, width: getWidth(2.5) }}
          label="Start Date"
          value={dayjsIfExists(eventFilters.startDate)}
          onChange={(value) =>
            setEventFilters({ startDate: dayjsIfValid(value) })
          }
        />
        <DatePicker
          sx={{ m: 1, width: getWidth(2.5) }}
          label="End Date"
          value={dayjsIfExists(eventFilters.endDate)}
          onChange={(value) =>
            setEventFilters({ endDate: dayjsIfValid(value) })
          }
        />
      </LocalizationProvider>
      <PitchSlider
        minValue={eventFilters.pitchXMin}
        setMinValue={(value) => setEventFilters({ pitchXMin: value })}
        maxValue={eventFilters.pitchXMax}
        setMaxValue={(value) => setEventFilters({ pitchXMax: value })}
        label="X Range"
        minPossibleValue={rugbyPitch.xMin}
        maxPossibleValue={rugbyPitch.xMax}
        marks={[-10, 0, 22, 50, 78, 100, 110]}
        widthFactor={4.4}
      />
      <br />
      <PitchSlider
        minValue={eventFilters.pitchYMin}
        setMinValue={(value) => setEventFilters({ pitchYMin: value })}
        maxValue={eventFilters.pitchYMax}
        setMaxValue={(value) => setEventFilters({ pitchYMax: value })}
        label="Y Range"
        minPossibleValue={rugbyPitch.yMin}
        maxPossibleValue={rugbyPitch.yMax}
        marks={[0, 5, 20, 35, 50, 65, 70]}
        widthFactor={4.4}
      />
    </Box>
  );
};
