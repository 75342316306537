import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";
import { SimpleSelectOption } from "./simpleSelectOptions";
import { TooltipChip } from "../TooltipChip";

type Props<T> = {
  value: T;
  setValue: (value: T) => void;
  label: string;
  options: SimpleSelectOption[];
  disabled?: boolean;
  none?: boolean;
  dense?: boolean;
  widthFactor?: number;
};

export const SingleSelect = (props: Props<string>) => SimpleSelect(props);
export const MultiSelect = (props: Props<string[]>) => SimpleSelect(props);

export const getWidth = (widthFactor: number = 1) => 9 * widthFactor + "em";

const SimpleSelect = <T extends string | string[]>({
  value,
  setValue,
  label,
  options,
  disabled,
  none,
  dense,
  widthFactor = 1,
}: Props<T>) => {
  // assumes the generic T will always be of type string | string[]
  const multiple = typeof value !== "string";

  const getOptionValue = (id: string): string =>
    options.find((option) => option.id === id)!.value;

  const handleChange = (e: SelectChangeEvent<T>) => {
    setValue(e.target.value as T);
  };

  const renderValue = (selected: T) => (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
      {/*should never get to a state where this is called if not multiple*/}
      {(selected as string[]).map((id) => (
        <TooltipChip value={getOptionValue(id)} maxVisibleLength={12} />
      ))}
    </Box>
  );

  const width = getWidth(widthFactor);

  return (
    <FormControl
      sx={{
        m: 1,
        width: width,
        ...(multiple && { minHeight: 65 }),
      }}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        label={label}
        onChange={handleChange}
        {...(multiple && { renderValue })}
        multiple={multiple}
        disabled={disabled}
        sx={{
          height: "3.5em",
          ".MuiBox-root": {
            height: "2.5em",
            overflowY: "scroll",
          },
          width,
        }}
      >
        {none && (
          <MenuItem value="">
            <em>No Selection</em>
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id} dense={dense}>
            {multiple ? (
              <>
                <Checkbox
                  checked={value?.includes(option.id)}
                  sx={dense ? { py: 0 } : {}}
                />
                <ListItemText primary={option.value} />
              </>
            ) : (
              option.value
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
