import { Chip, ChipProps, Tooltip, TooltipProps } from "@mui/material";

export type TooltipChipProps = {
  value: string;
  maxVisibleLength?: number;
  onDelete?: () => void;
  tooltipProps?: Partial<TooltipProps>;
  chipProps?: Partial<ChipProps>;
};

export const TooltipChip = ({
  value,
  maxVisibleLength = Number.MAX_VALUE,
  onDelete,
  tooltipProps = {},
  chipProps = {},
}: TooltipChipProps) => {
  const chip = (
    <Chip
      {...chipProps}
      label={
        value.length < maxVisibleLength
          ? value
          : value.substring(0, maxVisibleLength - 2) + "..."
      }
      size="small"
      onDelete={onDelete}
    />
  );
  return (
    <Tooltip
      {...tooltipProps}
      key={value}
      title={value}
      enterDelay={0}
      followCursor={true}
    >
      {chip}
    </Tooltip>
  );
};
