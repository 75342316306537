import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { HttpRequestMethod, send } from "./utils/send";
import { PostTempIdResponse } from "./utils/PostTempIdResponse";
import { getUseCreate } from "./utils/useCrud/getUseCreate";
import { getUseUpdate } from "./utils/useCrud/getUseUpdate";
import { getUseDelete } from "./utils/useCrud/getUseDelete";

// This must be kept up-to-date with the backend.
export type OrganizationPermissions = {
  max_matches?: number;
  max_users?: number;
  video_review_enabled: boolean;
};

export type UserResponse = User & {
  organization_name?: string;
  organization_permissions?: OrganizationPermissions | null;
  user_agreement_signed?: boolean;
};

// This must be kept up-to-date with the database.
export enum UserPermission {
  owner = 1,
  write = 2,
  read = 3,
}

const sendGetUser = (): Promise<UserResponse> =>
  send<UserResponse>("/users/self", HttpRequestMethod.GET);

export type User = {
  id: number;
  // cognito_external_id?: string;
  organization_id?: number;
  permission_level_id?: UserPermission;
  name?: string;
  email: string;
};

const sendGetUsers = (): Promise<User[]> =>
  send<UserResponse[]>("/users", HttpRequestMethod.GET);

const sendPostInternalUser = (user: User): Promise<PostTempIdResponse> =>
  send<PostTempIdResponse>(
    "/users/internal",
    HttpRequestMethod.POST,
    user
  ).then((response) => ({
    ...response,
    temp_id: user.id,
  }));

const sendPostExternalUser = () =>
  send<number>("/users/external", HttpRequestMethod.POST);

const sendPutUser = (user: User): Promise<boolean> =>
  send<boolean>(`/users/${user.id}`, HttpRequestMethod.PUT, user);

const sendDeleteUser = (id: number): Promise<boolean> =>
  send<boolean>(`/users/${id}`, HttpRequestMethod.DELETE);

export const useCreateExternalUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: sendPostExternalUser,
    onSuccess: (response) => {
      if (!response) {
        alert(
          "Error creating external user. Please refresh and try again or contact support."
        );
        return;
      }

      // We could mutate the user ourselves via onMutate, but it is simpler and reduces the opportunity for bugs to
      // invalidate and re-fetch the user.
      return queryClient.invalidateQueries(["user", "external"]);
    },
  });
};

const useUserQuery = () =>
  useQuery(["user"], sendGetUser, { staleTime: 5 * 60 * 1000 /* 5 minutes */ });

// TODO: pay attention to cache here, probably need to clear it on logout or other places like org creation
export const useUser = (): UserResponse | undefined => useUserQuery()?.data;

export const sendPostUserAgreementSignature = (): Promise<number> =>
  send<number>("/user-agreement-signatures", HttpRequestMethod.POST);
export const useSignUserAgreement = (): (() => Promise<void>) => {
  const userQuery = useUserQuery();
  return () =>
    sendPostUserAgreementSignature()
      // We must re-fetch because user.permissions will already be populated as null, and we don't manually fetch the user.
      .then(() => userQuery.refetch())
      .then(() => {});
};

export const sendPutHandleCheckout = (
  checkoutSessionId: string
): Promise<boolean> => {
  return send<boolean>(
    `/handle-checkout/${checkoutSessionId}`,
    HttpRequestMethod.PUT
  );
};

export const usePutHandleCheckout = (): ((
  checkoutSessionId: string
) => Promise<void>) => {
  const userQuery = useUserQuery();
  return (checkoutSessionId: string) =>
    sendPutHandleCheckout(checkoutSessionId)
      // We must re-fetch because user.permissions will already be populated as null, and we don't manually fetch the user.
      .then(() => userQuery.refetch())
      .then(() => {});
};

const useUsersQuery = () => {
  const user = useUser();
  return useQuery(["users"], sendGetUsers, {
    enabled: user?.permission_level_id !== undefined,
  });
};

export const useUsers = (): UserResponse[] | undefined => useUsersQuery()?.data;

export const useGetUser = (): ((id: number | null) => User | undefined) => {
  const users = useUsers();
  return (id: number | null) => users?.find((user) => user.id === id);
};

export const useCreateInternalUser = getUseCreate<User>(
  ["users"],
  sendPostInternalUser
);

export const useUpdateUser = getUseUpdate(["users"], sendPutUser);

export const useDeleteUser = getUseDelete(["users"], sendDeleteUser);
