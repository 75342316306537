import { useQuery } from "@tanstack/react-query";
import { HttpRequestMethod, send } from "./utils/send";

export type MetricEvent = {
  match_id: number;
  event_id: number;
  home_team: string;
  away_team: string;
  team: string;
  color: string;
  half_number: number;
  match_clock: string;
  action: string;
  x: number;
  y: number;
  possession: number;
  meters_gained: number;
  seconds_elapsed: number;
  points: number;
  tackles: number;
  penalties: number;
  gain_line: number;
  territory: number;
  inside22: number;
  phase: number;
  kick: number;
};

export const sendGetMatchMetricEvents = async (
  matchId: number
): Promise<[MetricEvent]> =>
  send<[MetricEvent]>(
    `/matches/${matchId}/metric-events`,
    HttpRequestMethod.GET
  );

export const useMatchMetricEvents = (
  matchId?: number
): MetricEvent[] | undefined =>
  useQuery(["metricEvent", "match", matchId], () =>
    matchId
      ? sendGetMatchMetricEvents(matchId)
      : Promise.resolve([] as MetricEvent[])
  ).data;
