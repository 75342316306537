import React from "react";
import { RotatingLines } from "react-loader-spinner";
import { ColorPalette } from "../utils/constants";

export const CustomSpinner = () => (
  <div
    style={{
      height: "50vh",
      display: "flex",
      justifyContent: "center",
    }}
  >
    <RotatingLines strokeColor={ColorPalette.blue} />
  </div>
);
