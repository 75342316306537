import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import * as React from "react";

export const Hero = ({
  title,
  text,
}: {
  title?: string;
  text?: string | string[];
}) => (
  <Container
    disableGutters
    maxWidth="sm"
    component="main"
    sx={{ pt: 8, pb: 6 }}
  >
    {title && (
      <Typography
        component="h1"
        variant="h3"
        align="center"
        color="text.primary"
        gutterBottom
      >
        {title}
      </Typography>
    )}
    {text &&
      (Array.isArray(text) ? text : [text])?.map((line, idx) => (
        <Typography
          key={idx}
          variant="h5"
          align="center"
          color="text.secondary"
          component="p"
        >
          {line}
        </Typography>
      ))}
  </Container>
);
