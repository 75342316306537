import { Box, Grid, Input, Slider, Typography } from "@mui/material";

type Props = {
  minValue: string;
  setMinValue: (value: string) => void;
  maxValue: string;
  setMaxValue: (value: string) => void;
  label: string;
  minPossibleValue: number;
  maxPossibleValue: number;
  marks: number[];
  widthFactor?: number;
};
export const PitchSlider = ({
  minValue,
  setMinValue,
  maxValue,
  setMaxValue,
  label,
  minPossibleValue,
  maxPossibleValue,
  marks,
  widthFactor = 1,
}: Props) => {
  const markLabels = marks.map((mark) => ({
    value: mark,
    label: mark,
  }));

  minValue = minValue.length === 0 ? minPossibleValue.toString() : minValue;
  maxValue = maxValue.length === 0 ? maxPossibleValue.toString() : maxValue;

  const handleUpdate = (
    newMinValue: string | number,
    newMaxValue: string | number
  ) => {
    newMinValue = newMinValue.toString();
    newMaxValue = newMaxValue.toString();

    setMinValue(parseInt(newMinValue) === minPossibleValue ? "" : newMinValue);
    setMaxValue(parseInt(newMaxValue) === maxPossibleValue ? "" : newMaxValue);
  };

  const handleBlur = () => {
    const newMinValue = Math.min(
      Math.max(parseInt(minValue), minPossibleValue),
      maxPossibleValue
    );
    const newMaxValue = Math.min(
      Math.max(parseInt(maxValue), minPossibleValue),
      maxPossibleValue
    );

    handleUpdate(newMinValue, newMaxValue);
  };

  const inputProps = {
    step: 1,
    min: minPossibleValue,
    max: maxPossibleValue,
    type: "number",
  };

  return (
    <Box
      sx={{
        m: 2,
        width: 10 * widthFactor + "em",
      }}
    >
      <Grid container spacing={1} margin={1} justifyContent="center">
        <Grid container justifyContent="space-between" alignItems="flex-end">
          <Grid item justifyContent="flex-start">
            <Input
              value={minValue}
              size="small"
              onChange={(e) => handleUpdate(e.target.value, maxValue)}
              onBlur={handleBlur}
              inputProps={inputProps}
            />
          </Grid>
          <Grid item justifyContent="center">
            <Typography>{label}</Typography>
          </Grid>
          <Grid item justifyContent="flex-end">
            <Input
              value={maxValue}
              size="small"
              onChange={(e) => handleUpdate(minValue, e.target.value)}
              onBlur={handleBlur}
              inputProps={inputProps}
            />
          </Grid>
        </Grid>
        <Grid item width="100%" justifyContent="center">
          <Slider
            value={[parseInt(minValue), parseInt(maxValue)]}
            onChange={(_, _value) => {
              handleUpdate(...(_value as [number, number]));
            }}
            min={minPossibleValue}
            max={maxPossibleValue}
            marks={markLabels}
            valueLabelDisplay="auto"
          />
        </Grid>
      </Grid>
    </Box>
  );
};
