import { SingleActionConfig } from "./SingleActionConfig";
import { DatabaseAction } from "../Action";
import { PossessionChange } from "../../../utils/actionMappings";
import { DetailedEvent } from "../../../requests/matchEvents";

export class TurnoverActionConfig extends SingleActionConfig {
  constructor(action: DatabaseAction, nextActions: DatabaseAction[]) {
    const requiresPitchClick = (previousEvents: DetailedEvent[]) =>
      previousEvents.length !== 0 &&
      ![
        DatabaseAction.lineout,
        DatabaseAction.maulStart,
        DatabaseAction.maulEnd,
        DatabaseAction.ruck,
        DatabaseAction.scrum,
        DatabaseAction.tackled,
      ].includes(previousEvents[0].action_type_id);
    super(action, nextActions, requiresPitchClick, PossessionChange.nextEvent);
  }
}
