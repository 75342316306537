import { QueryKey, useMutation, useQueryClient } from "@tanstack/react-query";
import { CrudType } from "./CrudType";

export const getUseDelete =
  <T extends CrudType>(
    queryKey: QueryKey,
    sendDeleteItem: (id: number) => Promise<boolean>
  ) =>
  () => {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (id: number) => sendDeleteItem(id),
      onMutate: async (id) =>
        queryClient
          .cancelQueries([...queryKey, id])
          .then(() =>
            queryClient.setQueriesData(queryKey, (prevItems: T[] = []) =>
              prevItems.filter((prevItem) => prevItem.id !== id)
            )
          ),
    });
  };
