import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
  onClick: () => void;
};

export const AnchorButton = ({ children, onClick }: Props) => (
  <a
    href="/"
    onClick={(e) => {
      e.preventDefault();
      onClick();
    }}
  >
    {children}
  </a>
);
