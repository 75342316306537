import { UseQueryResult } from "@tanstack/react-query";
import { useEffect } from "react";

export const useOnQueryComplete = <T>(
  queryResult: UseQueryResult<T, unknown>,
  onComplete: (data: T) => void
) => {
  const { data, isLoading } = queryResult;
  useEffect(() => {
    if (!isLoading) {
      return onComplete(data as T);
    }
  }, [data, isLoading, onComplete]);
};
