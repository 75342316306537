import { useCallback, useRef } from "react";
import { VideoPlayerRef } from "../components/VideoPlayer";

export const useVideoRef = () => {
  const videoRef = useRef<VideoPlayerRef>(null);
  const video = videoRef.current;
  const pauseVideo = useCallback(() => video?.pause(), [video]);
  const playVideo = useCallback(() => video?.play(), [video]);
  const getTimeVideo = useCallback(() => video?.getTime(), [video]);
  const seekToVideo = useCallback(
    (timeInMs: number) => video?.seekTo(timeInMs),
    [video]
  );
  return { video, videoRef, pauseVideo, playVideo, getTimeVideo, seekToVideo };
};
