import { NonDatabaseActionActionConfig } from "./NonDatabaseActionActionConfig";
import { DatabaseAction, ExpandedAction } from "../../Action";
import { Dispatch, SetStateAction } from "react";
import { addOrDeleteItemInSet } from "../../../../utils/addOrDeleteItemInSet";
import { Match } from "../../../../requests/matches";
import { DetailedEvent } from "../../../../requests/matchEvents";

export class OverrideActionConfig extends NonDatabaseActionActionConfig {
  constructor() {
    super(ExpandedAction.override);
  }

  getButtonPhrase = () => "Override";
  isValid = () => true;
  doAction = async (
    _previousEvents: DetailedEvent[],
    _createEvent: (
      action: DatabaseAction,
      possessionTeamId: number,
      locX: number,
      locY: number
    ) => void,
    _pseudoEventActions: Set<ExpandedAction>,
    setPseudoEventActions: Dispatch<SetStateAction<Set<ExpandedAction>>>,
    _match: Match,
    _locX?: number,
    _locY?: number
  ): Promise<void> =>
    setPseudoEventActions((prevState) =>
      addOrDeleteItemInSet(prevState, ExpandedAction.override)
    );
}
