import { darken, lighten } from "@mui/material";

export const getColorTokens = (
  color: string
): { main: string; light: string; dark: string; contrastText: string } => ({
  main: color,
  light: lighten(color, 0.8),
  dark: darken(color, 0.6),
  contrastText: "#FFFFFF",
});
