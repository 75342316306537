import { Button, ButtonProps } from "@mui/material";
import React from "react";
import { useAuth } from "@aws-amplify/ui-react/internal";
import { useQueryClient } from "@tanstack/react-query";

export const SignOutButton = (buttonProps: ButtonProps) => {
  const cognitoUser = useAuth().user;
  const queryClient = useQueryClient();

  const onSignOut = () => {
    queryClient.clear();
    cognitoUser?.signOut();
    // Don't use navigate() so that we get a forceful state change.
    window.location.href = "/";
  };

  return (
    <Button onClick={onSignOut} {...buttonProps}>
      Sign Out
    </Button>
  );
};
