import { Button, ButtonGroup, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSignUserAgreement } from "../requests/users";
import React, { useState } from "react";
import { CustomSpinner } from "../components/CustomSpinner";

export const UserAgreementSubmission = () => {
  const navigate = useNavigate();
  const signUserAgreement = useSignUserAgreement();
  const [isSubmitting, setIsSubmitting] = useState(false);

  if (isSubmitting) {
    return <CustomSpinner />;
  }

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <ButtonGroup
        orientation="vertical"
        variant="contained"
        sx={{ width: "fit-content", padding: 4 }}
      >
        <Stack spacing={2}>
          <p>
            In order to continue with QuickTap you must agree to the{" "}
            <a
              href="https://getquicktap.com/user-agreement"
              target="_blank"
              rel="noreferrer"
            >
              User Agreement
            </a>
            , and{" "}
            <a
              href="https://getquicktap.com/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            .
          </p>
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              style={{ width: "fit-content" }}
              onClick={() => {
                setIsSubmitting(true);
                signUserAgreement().then(() => navigate("/"));
              }}
              variant="contained"
              disabled={isSubmitting}
            >
              Agree & Continue
            </Button>
          </div>
        </Stack>
      </ButtonGroup>
    </div>
  );
};
