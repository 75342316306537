import { ButtonGroup, Tooltip } from "@mui/material";
import React from "react";
import {
  Action,
  ExpandedAction,
} from "../../business-logic/ActionBehavior/Action";

import { ColorButton } from "../ColorButton";
import { ACTION_CONFIGS } from "../../utils/actionMappings";
import { ActionButtonConfig } from "./ActionButtons";
import { useActionTypes } from "../../requests/actionTypes";
import { Team } from "../../requests/teams";
import { DetailedEvent } from "../../requests/matchEvents";
import {
  ASTERISK_KEYBOARD_SHORTCUT_INFO,
  useGetActionKeyboardShortcut,
  useKeyboardShortcuts,
} from "../../requests/keyboardShortcuts";

type Props = {
  isActionButtonsEnabled: boolean;
  previousEvents: DetailedEvent[];
  pseudoEventActions: Set<ExpandedAction>;
  onActionButtonClick: (action: Action, tags?: string[]) => void;
  actionsInButtonGroup: ActionButtonConfig[];
  homeTeam: Team;
  awayTeam: Team;
};

export const ActionButtonGroup = ({
  isActionButtonsEnabled,
  previousEvents,
  pseudoEventActions,
  onActionButtonClick,
  actionsInButtonGroup,
  homeTeam,
  awayTeam,
}: Props) => {
  const actionTypes = useActionTypes();
  const keyboardShortcuts = useKeyboardShortcuts();
  const getActionKeyboardShortcut = useGetActionKeyboardShortcut();

  return (
    <ButtonGroup orientation="vertical" sx={{ mt: 1 }}>
      {actionsInButtonGroup.map(({ action, color }, idx) => {
        const actionBehavior =
          action !== undefined ? ACTION_CONFIGS[action] : undefined;
        const keyboardShortcutString =
          action !== undefined ? getActionKeyboardShortcut(action) : undefined;

        const actionPhrase =
          actionBehavior?.getButtonPhrase(actionTypes, homeTeam, awayTeam) ??
          ".";

        const keyboardShortcutItems = keyboardShortcuts?.filter(
          (keyboardShortcut) => keyboardShortcut.action === action
        );

        const tooltipTitle =
          keyboardShortcutString !== ASTERISK_KEYBOARD_SHORTCUT_INFO ? (
            ""
          ) : (
            <>
              {keyboardShortcutItems
                ?.sort((a, b) => a.shortcut.localeCompare(b.shortcut))
                ?.map((keyboardShortcutItem) => (
                  <p key={keyboardShortcutItem.shortcut}>
                    <div>
                      shortcut: {keyboardShortcutItem.shortcut.toUpperCase()}
                      <br />
                    </div>
                    <div>
                      tags:{" "}
                      {keyboardShortcutItem.tags.length === 0
                        ? "NONE"
                        : keyboardShortcutItem.tags
                            .map((tag) => tag.value)
                            .join(", ")}
                    </div>
                  </p>
                ))}
            </>
          );

        return (
          <ColorButton
            // `blank-${idx}` is necessary so that each item in list has a unique key
            key={action ?? `blank-${idx}`}
            onClick={
              action !== undefined
                ? () => onActionButtonClick(action)
                : undefined
            }
            disabled={
              (!isActionButtonsEnabled ||
                !actionBehavior?.isValid(
                  previousEvents,
                  pseudoEventActions,
                  ACTION_CONFIGS
                )) ??
              true
            }
            variant="contained"
            size="small"
            color={color}
            sx={{
              textTransform: "none",
              mx: 0.25,
              my: 0.5,
              fontSize: ".71vw",
            }}
          >
            <Tooltip
              key={action ?? `blank-${idx}`}
              title={tooltipTitle}
              enterDelay={1000}
              followCursor={true}
            >
              <div>
                <strong>
                  {actionPhrase +
                    (keyboardShortcutString
                      ? ` (${keyboardShortcutString.toUpperCase()})`
                      : "")}
                </strong>
              </div>
            </Tooltip>
          </ColorButton>
        );
      })}
    </ButtonGroup>
  );
};
