import { Button, ButtonGroup, Link, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SignOutButton } from "../components/SignOutButton";
import { Users } from "./Users";
import Box from "@mui/material/Box";
import { KeyboardShortcutGrid } from "../components/Account/KeyboardShortcutGrid";
import { AlertDialog } from "../components/AlertDialog";
import {
  defaultKeyboardShortcuts,
  useUpdateKeyboardShortcuts,
} from "../requests/keyboardShortcuts";

export const Account = () => {
  const navigate = useNavigate();

  const updateKeyboardShortcuts = useUpdateKeyboardShortcuts();
  const resetKeyboardShortcuts = () => {
    updateKeyboardShortcuts
      .mutateAsync(defaultKeyboardShortcuts)
      // TODO: forced reload isn't ideal, but it's working. Otherwise, the table doesn't re-render.
      .then(() => window.location.reload());
  };
  const [resetKeyboardShortcutsDialog, setResetKeyboardShortcutsDialog] =
    useState(false);

  return (
    <div style={{ margin: "2em" }}>
      <h2>Account Settings</h2>
      <ButtonGroup orientation="vertical" sx={{ width: "fit-content" }}>
        <Stack spacing={1}>
          <Button
            onClick={() => navigate("/subscription")}
            variant="outlined"
            sx={{ width: "auto" }}
          >
            Change Subscription
          </Button>
          <SignOutButton variant="outlined" sx={{ width: "auto" }} />
        </Stack>
      </ButtonGroup>

      <h2>My Keyboard Shortcuts</h2>
      <Button
        sx={{ m: 1 }}
        color="error"
        variant="contained"
        onClick={() => {
          setResetKeyboardShortcutsDialog(true);
        }}
      >
        Reset Shortcuts to Default
      </Button>
      <AlertDialog
        message="Reset your keyboard shortcuts?"
        isOpen={resetKeyboardShortcutsDialog}
        onCloseDialog={() => setResetKeyboardShortcutsDialog(false)}
        onConfirmDialog={resetKeyboardShortcuts}
        confirmText="Reset"
      />

      <KeyboardShortcutGrid />

      <h2>Organization Users</h2>
      <Box
        sx={{
          height: "40vh",
        }}
      >
        <Users />
      </Box>

      <h2>Contact QuickTap</h2>
      <Typography>
        Feedback, questions, or bug reports? Email us at{" "}
        <Link href="mailto:support@getquicktap.com">
          support@getquicktap.com
        </Link>
      </Typography>
    </div>
  );
};
