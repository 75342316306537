import {
  FullFeaturedCrudGrid,
  FullFeaturedCrudGridProps,
} from "./FullFeaturedCrudGrid";
import { GridValidRowModel } from "@mui/x-data-grid/models/gridRows";

export type FullFeaturedCrudGridNoIdBlobProps<T> = Omit<
  FullFeaturedCrudGridProps<T>,
  "crudRequests" | "validators"
> & {
  crudRequests: {
    isStoredAsBlob: true;
    useCollection: () => T[] | undefined;
    putCollection: (body: T[]) => void;
  };
  validators: {
    isValidCreateUpdate: (
      item: T & { id: number },
      items: (T & { id: number })[]
    ) => boolean | string;
    isValidDelete?: (item: T & { id: number }) => boolean;
  };
};

export const FullFeaturedCrudGridNoIdBlob = <T extends GridValidRowModel>({
  crudRequests,
  validators,
  columns,
  getNewRow,
  getDeleteDialog,
  unableToDeleteTooltip,
  itemName,
  lockKey,
  initialSortModel,
  fieldToFocus,
}: FullFeaturedCrudGridNoIdBlobProps<T>) => {
  return (
    <FullFeaturedCrudGrid
      crudRequests={{
        isStoredAsBlob: crudRequests.isStoredAsBlob,
        useCollection: () =>
          crudRequests.useCollection()?.map((item, index) => ({
            ...item,
            id: index,
          })),
        putCollection: (items) =>
          crudRequests.putCollection(
            // We need to remove isNew, as we are storing this directly on the database as JSONB.
            // TODO: Validate the above issue on the backend.
            items.map(({ id, isNew, ...rest }) => rest as T & { id: number })
          ),
      }}
      validators={validators}
      columns={columns}
      getNewRow={(id) => ({
        ...getNewRow(id),
        id,
      })}
      getDeleteDialog={getDeleteDialog}
      unableToDeleteTooltip={unableToDeleteTooltip}
      itemName={itemName}
      lockKey={lockKey}
      initialSortModel={initialSortModel}
      fieldToFocus={fieldToFocus}
    />
  );
};
