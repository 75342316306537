import {
  ALPHABET,
  KeyboardShortcutItem,
  useKeyboardShortcuts,
  useUpdateKeyboardShortcuts,
} from "../../requests/keyboardShortcuts";
import { GridColDef } from "@mui/x-data-grid";
import { FullFeaturedCrudGridNoIdBlob } from "../CrudTable/FullFeaturedCrudGridNoIdBlob";
import { SelectGridCol } from "../CrudTable/columns/SelectGridCol";
import { LARGE_COLUMN, XL_COLUMN } from "../../utils/columnSizes";
import { Action } from "../../business-logic/ActionBehavior/Action";
import { useActionTypes } from "../../requests/actionTypes";
import { ACTION_CONFIGS } from "../../utils/actionMappings";
import { Team } from "../../requests/teams";
import { ActionConfig } from "../../business-logic/ActionBehavior/ActionConfig/ActionConfig";
import { useTagOptions } from "../simple-select/simpleSelectOptions";
import { MultiSelectGridCol } from "../CrudTable/columns/MultiSelectGridCol";
import { getTypedEntries } from "../../utils/getTypedEntries";

// This is now codified as the specified event name in the database's keyboard_shortcuts table.
export const TAG_PREVIOUS_EVENT = "TAG PREVIOUS EVENT";

export const KeyboardShortcutGrid = () => {
  // const updateKeyboardShortcuts = useUpdateKeyboardShortcuts().mutateAsync;
  const updateKeyboardShortcuts = useUpdateKeyboardShortcuts().mutate;
  const tagOptions = useTagOptions();
  const databaseActionTypes = useActionTypes();

  const getButtonPhrase = (actionConfig: ActionConfig) =>
    actionConfig.getButtonPhrase(
      databaseActionTypes,
      { name: "Home Team" } as Team,
      { name: "Away Team" } as Team
    );

  const columns: GridColDef[] = [
    // TODO: Center the value in this column.
    new SelectGridCol(
      "shortcut",
      "Shortcut",
      LARGE_COLUMN,
      ALPHABET.split("").map((char) => ({
        value: char,
        label: char,
      })),
      (params) => `"${params.value}"`
    ),
    new SelectGridCol(
      "action",
      "Action",
      XL_COLUMN,
      [
        { value: TAG_PREVIOUS_EVENT, label: TAG_PREVIOUS_EVENT },
        ...getTypedEntries(ACTION_CONFIGS)
          .map(([value, actionConfig]) => ({
            value,
            label: getButtonPhrase(actionConfig),
          }))
          // Optional chaining is used to prevent a crash before the label exists on initial page load.
          .sort((a, b) => a.label?.localeCompare(b.label)),
      ],
      (params) => {
        if (params.value === TAG_PREVIOUS_EVENT) {
          return TAG_PREVIOUS_EVENT;
        }
        return getButtonPhrase(ACTION_CONFIGS[params.value as Action]);
      }
    ),
    // TODO: Shortcuts can only be set up with existing tags. We should allow a method of doing it with new tags.
    new MultiSelectGridCol("tags", "Tags", tagOptions ?? []),
  ];

  const getNewRow = (id: number): KeyboardShortcutItem & { id: number } => ({
    id,
    shortcut: "",
    action: TAG_PREVIOUS_EVENT,
    tags: [],
  });

  const getDeleteDialog = (keyboardShortcut: KeyboardShortcutItem) =>
    `Delete shortcut for ${keyboardShortcut?.shortcut}?`;

  return (
    <FullFeaturedCrudGridNoIdBlob
      crudRequests={{
        isStoredAsBlob: true,
        useCollection: useKeyboardShortcuts,
        putCollection: (body) =>
          updateKeyboardShortcuts(
            body.map((keyboardShortcut) => {
              return {
                ...keyboardShortcut,
                tags: keyboardShortcut.tags.map((tag) => tag),
              };
            })
          ),
      }}
      validators={{
        isValidCreateUpdate: (item, items) => {
          if (item.shortcut === "") {
            return false;
          }
          if (
            items
              .filter((_item) => item.id !== _item.id)
              .some((_item) => _item.shortcut === item.shortcut)
          ) {
            return "Shortcut already exists.";
          }
          if (item.action === TAG_PREVIOUS_EVENT && item.tags.length === 0) {
            return "Previous event shortcut must have at least one tag.";
          }

          return true;
        },
      }}
      columns={columns}
      getNewRow={getNewRow}
      getDeleteDialog={getDeleteDialog}
      itemName="keyboard shortcut"
      initialSortModel={[{ field: "shortcut", sort: "asc" }]}
      fieldToFocus="shortcut"
    />
  );
};
