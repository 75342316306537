import { SingleActionConfig } from "./SingleActionConfig";
import { Action, DatabaseAction } from "../Action";
import { getTeamShortName } from "../../../utils/getTeamShortName";
import assert from "assert";

export class DynamicPossessionActionConfig extends SingleActionConfig {
  constructor(
    action: Action,
    databaseActionConfig: SingleActionConfig,
    phrase: string,
    isHomeTeamPossession: boolean
  ) {
    assert(
      databaseActionConfig.action in DatabaseAction,
      "The databaseActionConfig action must be of type DatabaseAction."
    );
    super(
      action,
      databaseActionConfig.nextActions,
      databaseActionConfig.getRequiresPitchClick,
      databaseActionConfig.possessionChange,
      databaseActionConfig.action as DatabaseAction
    );

    this.getClickPhrase = () => phrase;
    this.getButtonPhrase = (_, homeTeam, awayTeam) =>
      `${getTeamShortName(
        (isHomeTeamPossession ? homeTeam : awayTeam).name
      )} ${phrase}`;
    this.getIsHomeTeamPossession = () => isHomeTeamPossession;
  }
}
