import { EventGridRowModel } from "../../components/EventGrid";
import React, { ReactElement } from "react";
import { CommentInput } from "../../components/CommentInput";

export const useCommentColumn = (
  pauseVideo: () => void
): ((row: EventGridRowModel) => ReactElement) => {
  return (row: EventGridRowModel) => (
    <CommentInput event={row} pauseVideo={pauseVideo} />
  );
};
