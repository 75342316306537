import { HttpRequestMethod, send } from "./utils/send";
import { getUseDelete } from "./utils/useCrud/getUseDelete";
import { PostTempIdResponse } from "./utils/PostTempIdResponse";
import assert from "assert";
import { useUser } from "./users";
import { useQuery } from "@tanstack/react-query";
import { getUseUpdate } from "./utils/useCrud/getUseUpdate";
import { getUseCreate } from "./utils/useCrud/getUseCreate";

export type Match = {
  id: number;
  home_team_id: number | "";
  away_team_id: number | "";
  match_type_id: number | "";
  start_time: Date;
  location: string;
  video_url: string;
};

const translateMatch = (match: Match): Match => {
  assert(match.start_time);
  return {
    ...match,
    start_time: new Date(match.start_time),
  };
};

const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};

export const sendGetMatches = (): Promise<Match[]> =>
  send<Match[]>("/matches", HttpRequestMethod.GET).then((matches) =>
    matches.map((match) => translateMatch(match))
  );

export const useMatches = (): Match[] | undefined => {
  const user = useUser();
  return useQuery(["matches"], sendGetMatches, {
    enabled: user?.permission_level_id !== undefined,
  }).data;
};

// export const sendGetMatch = (id: number): Promise<Match> =>
//   send<Match>(`/matches/${id}`, HttpRequestMethod.GET).then(translateMatch);

export const useMatch = (id?: number): Match | undefined =>
  useMatches()?.find((match) => match.id === id);

export const useGetMatch = (): ((id: number | null) => Match | undefined) => {
  const matches = useMatches();
  return (id: number | null) => matches?.find((match) => match.id === id);
};

export type PostMatchRequest = {
  home_team_id: number;
  away_team_id: number;
  match_type_id: number;
  minutes_per_half: number;
  start_time: Date;
  location: string;
  video_url: string;
};

export type PostMatchResponse = {
  id: number;
};

export const sendPostMatch = (body: PostMatchRequest): Promise<Match> =>
  send<PostMatchResponse>("/matches", HttpRequestMethod.POST, body).then(
    (response) => ({ id: response.id, ...body, is_deleted: false } as Match)
  );
export const sendPostMatch2 = (match: Match): Promise<PostTempIdResponse> =>
  send<PostTempIdResponse>("/matches", HttpRequestMethod.POST, {
    ...match,
    start_time: formatDate(match.start_time),
  }).then((response) => ({
    ...response,
    temp_id: match.id,
  }));

const sendPutMatch = (match: Match): Promise<boolean> =>
  send<boolean>(`/matches/${match.id}`, HttpRequestMethod.PUT, {
    ...match,
    start_time: formatDate(match.start_time),
  });

export const sendDeleteMatch = async (id: number): Promise<boolean> =>
  send<boolean>(`/matches/${id}`, HttpRequestMethod.DELETE);

export const useCreateMatch = getUseCreate(["matches"], sendPostMatch2);
export const useUpdateMatch = getUseUpdate(["matches"], sendPutMatch);
export const useDeleteMatch = getUseDelete(["matches"], sendDeleteMatch);
