import { RefObject } from "react";
import { useEffectOnce } from "usehooks-ts";

export const useWaitForRef = <T>(
  ref: RefObject<T>,
  callback: (refCurrent: T) => void,
  customWaitCondition?: (refCurrent: T) => boolean,
  maxWaitMs: number = 10000,
  refreshRateMs: number = 10
) =>
  // The callback does not run until the ref is non-null.
  useEffectOnce(() => {
    const intervalId = setInterval(() => {
      if (
        ref.current &&
        (!customWaitCondition || customWaitCondition(ref.current))
      ) {
        clearInterval(intervalId);
        callback(ref.current);
      }
    }, refreshRateMs);

    // In the event the ref remains null, clear the interval to prevent an infinite loop.
    setTimeout(() => clearInterval(intervalId), maxWaitMs);
  });
