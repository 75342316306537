import { HttpRequestMethod, send } from "./utils/send";
import {
  Action,
  AllDatabaseActions,
} from "../business-logic/ActionBehavior/Action";
import { useUser } from "./users";
import { useQuery } from "@tanstack/react-query";
import { useOnQueryComplete } from "../hooks/useOnQueryComplete";
import assert from "assert";

export type ActionType = {
  id: Action;
  name: string;
};

export const sendGetActionTypes = async (): Promise<ActionType[]> =>
  send<ActionType[]>("/action-types", HttpRequestMethod.GET);

export const useActionTypes = (): Record<Action, string> => {
  const user = useUser();
  const query = useQuery(["actionTypes"], sendGetActionTypes, {
    enabled: user?.permission_level_id !== undefined,
  });
  useOnQueryComplete(query, (actionTypes) => {
    AllDatabaseActions.forEach((action) =>
      assert(
        actionTypes.map((actionType) => actionType.id).includes(action),
        `Action type not found on database: ${action}`
      )
    );
  });
  return (
    query.data?.reduce((accelerator, actionType) => {
      accelerator[actionType.id] = actionType.name;
      return accelerator;
    }, {} as Record<Action, string>) ?? ({} as Record<Action, string>)
  );
};
