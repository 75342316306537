import { QueryKey, useMutation, useQueryClient } from "@tanstack/react-query";
import { PostTempIdResponse } from "../PostTempIdResponse";
import { CrudType } from "./CrudType";

// TODO: this should not cancel queries unless it's the same new item
export const getUseCreate =
  <T extends CrudType>(
    queryKey: QueryKey,
    sendPostItem: (item: T) => Promise<PostTempIdResponse>
  ) =>
  () => {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (item: T) => sendPostItem(item),
      onMutate: async (item) =>
        queryClient.cancelQueries([...queryKey, item.id]).then(() =>
          queryClient.setQueryData(
            [...queryKey, item.id],
            (prevItems: T[] = []) => {
              return [...prevItems, item];
            }
          )
        ),
      onSuccess: (postItemResponse) => {
        if (!postItemResponse) {
          alert("Error creating item. Please refresh and try again.");
          return;
        }
        // This updates the temporary item id with the real one.
        queryClient.setQueryData(queryKey, (prevItems: T[] = []) =>
          prevItems.map((prevItem) =>
            prevItem.id === postItemResponse.temp_id
              ? { ...prevItem, id: postItemResponse.id }
              : prevItem
          )
        );
      },
    });
  };
