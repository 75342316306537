// Values must be aligned with action_types table.
// Commented action types are deprecated.

export enum DatabaseAction {
  // receiveKick = 2,
  // looseKick = 4,
  // scrum = 5,
  // ruck = 7,
  // penalty = 9,
  // lineout = 11,
  // looseTurnover = 12,
  // try = 13,
  // penaltyKick = 15,
  // madeKick = 18,
  // intoTouch = 19,
  // tapRestart = 20,
  // missedKick = 21,
  // knockOn = 22,
  // maulEnds = 29,
  // heldUp = 30,
  // kickForPoints = 34,
  conversionGoalKick = 101,
  // backsPhase = 102,
  ballGrounded = 103,
  chargedDownKick = 104,
  // boxKick = 105,
  // freeKickConceded = 106,
  penaltyWon = 107,
  dropGoalKick = 108,
  // forwardsPhase = 109,
  heldUpTry = 110,
  intoTouch = 111,
  // kickForGoal = 112,
  // kickoff0 = 113,
  // kickoff22 = 114,
  // kickoff50 = 115,
  knockOn = 116,
  lineout = 117,
  // looseTurnover = 118,
  goalKickMade = 119,
  mark = 120,
  maulStart = 121,
  maulEnd = 122,
  conversionKickMiss = 123,
  freeKickWon = 124,
  // penaltyConceded = 125,
  // outTheBack = 126,
  // pickAndGoPhase = 127,
  // possessionChange = 128,
  // punt = 129,
  receiveKick = 130,
  recoverKick = 131,
  ruck = 132,
  scrum = 133,
  // setPieceTurnover = 134,
  tapRestart = 135,
  try = 136,
  openPlayKick = 137,
  scrumReset = 138,
  kickoff = 139,
  kickRestart = 140,
  penaltyGoalKick = 141,
  // handlingTurnover = 142,
  // contactTurnover = 143,
  // breakdownTurnover = 144,
  endOfHalf = 145,
  offload = 146,
  tackled = 147,
  turnover = 148,
  phase = 149,
  flag = 150,
  forwardPass = 151,
}

// These actions are not in the database, but are used in the business logic. Their values should all be negative.
// WARNING: These actions are now used in the database in keyboard shortcuts.
export enum ExpandedAction {
  tackledAndRuck,
  homeTeamKnockOn,
  awayTeamKnockOn,
  homeTeamPenalty,
  awayTeamPenalty,
  homeTeamFreeKick,
  awayTeamFreeKick,
  possessionChange,
  override,
}

export type Action = DatabaseAction | ExpandedAction;

export const getActionString = (action: Action): string => {
  if (action in DatabaseAction) {
    return DatabaseAction[action];
  } else if (action in ExpandedAction) {
    return ExpandedAction[action];
  } else {
    throw new Error(`Invalid action: ${action}`);
  }
};

export const AllDatabaseActions: DatabaseAction[] = Object.values(
  DatabaseAction
)
  .filter((value) => !isNaN(Number(value)))
  .map((value) => value as DatabaseAction);
