import { EventGridRowModel } from "../EventGrid";
import { useGetTeam } from "../../requests/teams";
import Card from "@mui/material/Card";
import { Avatar, CardActionArea, Chip, lighten } from "@mui/material";
import { ColorPalette } from "../../utils/constants";
import CardHeader from "@mui/material/CardHeader";
import { pickContrastingColor } from "../../utils/pickContrastingColor";
import { CommentInput } from "../CommentInput";
import { renderTime } from "../../utils/time";
import { getMatchName } from "../../utils/getMatchName";
import { getDisplayDate } from "../../utils/getDisplayDate";
import CardContent from "@mui/material/CardContent";
import { TagsInput } from "../TagsInput";
import React from "react";
import { useActionTypes } from "../../requests/actionTypes";
import { useGetMatch } from "../../requests/matches";

type Props = {
  event: EventGridRowModel;
  eventIndex: number;
  isActiveEvent: boolean;
  onClickEventCard: (eventIndex: number) => void;
  pauseVideo: () => void;
};

export const EventCard = ({
  event,
  eventIndex,
  isActiveEvent,
  onClickEventCard,
  pauseVideo,
}: Props) => {
  const getMatch = useGetMatch();
  const getTeam = useGetTeam();
  const actionTypes = useActionTypes();

  const match = getMatch(event.match_id);

  return (
    <Card
      id={`event-card-${event.match_id}-${event.match_event_id}`}
      sx={{
        mb: 0.75,
        mr: 1,
        backgroundColor: isActiveEvent
          ? lighten(ColorPalette.blue, 0.8)
          : lighten(ColorPalette.gray, 0.95),
      }}
    >
      <CardActionArea
        component="span"
        onClick={(e) => {
          onClickEventCard(eventIndex);
          // The video will be played if the event index is changed.
          // playVideo();
          e.stopPropagation();
        }}
      >
        <CardHeader
          sx={{
            paddingBottom: 0,
            display: "flex",
            alignItems: "center",
            ".MuiCardHeader-action": {
              alignSelf: "center",
            },
          }}
          avatar={
            <Avatar
              sx={{
                bgcolor: event.possession_team?.color,
                color: pickContrastingColor(
                  event.possession_team?.color ?? "#FFFFFF"
                ),
                border: 1,
                borderColor: "#000000",
                fontFamily: "serif",
              }}
            >
              {event.possession_team?.name?.[0]}
            </Avatar>
          }
          action={
            <>
              <CommentInput
                event={event}
                pauseVideo={pauseVideo}
                disabled={!isActiveEvent}
              />
              {/*<IconButton component="span">*/}
              {/*  <MoreVertIcon />*/}
              {/*</IconButton>*/}
            </>
          }
          title={
            <span
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ marginRight: "0.5em" }}>
                {actionTypes[event.action_type_id]}
              </span>
              <Chip
                label={renderTime(event.event_time_in_ms)}
                variant="filled"
                size="small"
              />
            </span>
          }
          subheader={getMatchName(
            getTeam(match!.home_team_id)?.name,
            getTeam(match!.away_team_id)?.name,
            getDisplayDate(match!.start_time)
          )}
        />
        <CardContent
          sx={{
            py: 0,
            "&:last-child": {
              paddingBottom: 0,
            },
          }}
        >
          <TagsInput
            event={event}
            pauseVideo={pauseVideo}
            disabled={!isActiveEvent}
          />
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
